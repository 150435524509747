import { useEffect } from "react";

const ProspleSearchWidget1 = () => {

    let prospleScriptLoaded = false;

      useEffect(() => {

        prospleScriptLoaded = true;
        const configScriptId = "prosple-search-config";
        const externalScriptId = "prosple-search-script";

        if (!document.getElementById(configScriptId)) {
            const configScript = document.createElement("script");
            configScript.id = configScriptId;
            configScript.type = "text/javascript";
            configScript.textContent = `
                var $PROSPLE_SEARCH_ID = 41;
                var $PROSPLE_SEARCH_NODE_TYPE = 'career_opportunity';
                var $PROSPLE_SEARCH_ENDPOINT = 'https://in.prosple.com/search-jobs?locations=9826&defaults_applied=1&utm_medium=referral&utm_source=api&utm_campaign=rinex&utm_content=partner-promotion';
                var $PROSPLE_SEARCH_DROPDOWNS = [
                    {
                        label: "I'm looking for",
                        emptyOptionText: 'Any Opportunity Type',
                        filterCondition: 'opportunity_type',
                        facet: 'opportunity_types',
                    },
                    {
                        label: "I'm studying",
                        emptyOptionText: 'Any Study Field',
                        filterCondition: 'study_field',
                        facet: 'study_fields',
                    },
                ];
            `;
            document.head.appendChild(configScript);
        }

        if (!document.getElementById(externalScriptId)) {
            const externalScript = document.createElement("script");
            externalScript.id = externalScriptId;
            externalScript.src = "https://prosple-widgets.s3-ap-southeast-2.amazonaws.com/prosple/prosple-search-v2.0.js";
            externalScript.async = true;
            document.head.appendChild(externalScript);
        }
    }, []);

    
    return (
        <section id="prosple-search-widget">
            <style>{`

#prosple-search-widget .fieldset {
    display: flex;
    justify-content: center !important;
    margin-bottom: 30px !important;
}

#prosple-search-widget *,
#prosple-search-widget::before,
#prosple-search-widget::after {
box-sizing: border-box;
}

#prosple-search-widget form {
position: relative;
z-index: 0;
}

#prosple-search-widget form:last-child {
margin-bottom: 0px;
}

#prosple-search-widget form:first-child {
margin-top: 0px;
}

#prosple-search-widget .fieldset {
vertical-align: middle;
border: 0px none;
margin: 0px;
padding: 0px;
}

#prosple-search-widget .section--input {
display: inline-block;
margin: 0 0 20px 0;
border-radius: 3px;
background-color:var(--white-bg-color);
color: initial;
width: 100%;
}

#prosple-search-widget .section--input .input-label:first-child {
margin-top: 0px;
}

#prosple-search-widget .section--input .input-group {
border: none;
padding: 20px;
}

#prosple-search-widget .section--input .input-group:last-child {
border-bottom: 0px none;
}

#prosple-search-widget .section--input .input-label {
display: block;
color: rgb(114, 114, 114);
font-size: 11px;
font-weight: 500;
line-height: 18px;
letter-spacing: 1.3px;
text-transform: uppercase;
margin-bottom: 5px;
padding: 0px 0.5rem;
}

#prosple-search-widget .section--input .input-group .input {
border: 0px none;
display: inline-block;
margin: 0px;
position: relative;
background-color: var(--white-bg-color);
border-radius: 3px;
width: 100%;
}

#prosple-search-widget .section--input select,
#prosple-search-widget .section--input input[type='text'] {
font-size: 18px;
padding: 0.25rem 40px 0.25rem 0.5rem;
appearance: none;
-webkit-appearance: none;
background: transparent none repeat scroll 0% 0%;
border: 0px none;
-moz-appearance: none;
color: rgb(32, 32, 32);
line-height: 24px;
position: relative;
width: 100%;
z-index: 10;
display: inline-block;
font-family: inherit;
max-width: 100%;
text-transform: none;
margin: 0px;
cursor: pointer;
}

#prosple-search-widget .section--input .icon {
color: #28beff;
position: absolute;
right: 1em;
top: 50%;
transform: translateY(-50%);
z-index: 5;
display: inline-block;
font-style: normal;
font-variant: normal;
font-weight: normal;
line-height: 1em;
text-align: center;
text-decoration: inherit;
text-transform: none;
width: 1em;
}

#prosple-search-widget .section--input .icon--chevron-down::before {
content: '▼';
vertical-align: middle;
box-sizing: border-box;
}

#prosple-search-widget .input-group--actions {
text-align: right;
vertical-align: middle;
padding: 0;
margin: 0;
position: relative;
}

#prosple-search-widget .input-group--actions .button {
border-radius: 3px;
border-style: none;
font-weight: 500;
text-decoration: none;
border-radius: 3px;
cursor: pointer;
display: inline-block;
font-family: inherit;
line-height: 20px;
text-align: center;
vertical-align: middle;
background-color: #28beff;
border: 1px solid transparent;
color: rgb(255, 255, 255);
font-size: 14px;
padding: 10px 16px;
margin: 0px;
}

#prosple-search-widget .input-group--actions .button:hover {
opacity: 0.8;
}

#prosple-search-widget .input-group--actions .icon {
font-size: 2em;
margin-left: 0px;
margin-right: 0px;
vertical-align: middle;
position: relative;
display: inline-block;
font-style: normal;
font-variant: normal;
font-weight: normal;
line-height: 1em;
text-align: center;
text-decoration: inherit;
text-transform: none;
width: 1em;
}

#prosple-search-widget .input-group--actions .icon--search::before {
content: '⚲';
display: inline-block;
transform: rotate(-45deg);
}

#prosple-search-widget .input-group--actions .icon span {
border: 0px none;
clip: rect(0px, 0px, 0px, 0px);
height: 1px;
margin: -1px;
overflow: hidden;
padding: 0px;
position: absolute;
white-space: nowrap;
width: 1px;
clip-path: inset(50%);
}

@media (min-width: 64em) {
#prosple-search-widget .fieldset {
display: flex;
background:var(--white-bg-color);
}
#prosple-search-widget .fieldset * {
  vertical-align: middle;
}

#prosple-search-widget .section--input {
  display: inline-flex;
  margin: 0px;
  border-radius: 3px 0 0 3px;
  height: 90px;
  width: initial;
}

#prosple-search-widget .section--input .input-group {
  display: inline-block;
  border-bottom: 0px;
  border-right: 1px solid rgb(215, 215, 215);
}

#prosple-search-widget .section--input .input-group:last-child {
  border-right: 0px none;
}

#prosple-search-widget .input-group--actions {
  display: inline-block;
}

#prosple-search-widget .input-group--actions .button {
  height: 91px;
  border-radius: 0px 3px 3px 0px;
}
}
.form-inside{
margin-top: 20px !important;
width: 99% !important;
background-color: var(--header-bg-color);
}

@media (max-width: 768px) {
#prosple-search-widget .fieldset {
flex-direction: column; /* Stack elements vertically */
align-items: center;
width: 100%;
margin-bottom: 15px;
}

#prosple-search-widget .section--input {
width: 100%; /* Full width for input fields */
display: block;
margin-bottom: 15px;
}

#prosple-search-widget .section--input .input-group {
width: 100%;
padding: 10px;
}

#prosple-search-widget .section--input select,
#prosple-search-widget .section--input input[type='text'] {
font-size: 16px;
padding: 10px;
width: 100%;
}

#prosple-search-widget .input-group--actions {
text-align: center;
width: 100%;
}

#prosple-search-widget .input-group--actions .button {
width: 100%;
padding: 12px;
font-size: 16px;
}
}


            `}</style>
        </section>
    );
};

export default ProspleSearchWidget1;
