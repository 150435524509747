import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom"
import jobRoleDetailsData from "../data/jobrole.json"

export function JobRole(){
    const params = useParams();

    const[jobRoleData, setJobRoleData] = useState({});
    const[relatedRoleData, setRelatedRoleData] = useState([]);

    function setRoleData() {
        const selectedRole = jobRoleDetailsData.find(item => item.jobTitle === params.jobrole);
        const relatedRole = jobRoleDetailsData.filter(item => item.jobTitle !== params.jobrole);

        if (selectedRole) {
            setJobRoleData(selectedRole);
        }
        setRelatedRoleData(relatedRole)
    }

    function btnApplyNowClick() {
        document.getElementById("apply-button-slider").style.transform = "translateX(86%)";
        document.getElementById("apply-button-slider").style.transition = "0.7s";
        document.getElementById("apply-button-slider").style.backgroundColor = "red";
        
        setTimeout(function() {
            window.open("https://forms.gle/cux2RgfbTmroNJSx7", "_blank");
            document.getElementById("apply-button-slider").style.transform = "translateX(86%)";
        },600);
        setTimeout(function() {
            document.getElementById("apply-button-slider").style.transform = "translateX(0%)";    
            document.getElementById("apply-button-slider").style.backgroundColor = "rgba(33, 188, 255, 1)";
            document.getElementById("apply-button-slider").style.transition = "0s";
        },1000);

    }

    useEffect(()=>{
        setRoleData();
    },[params])

    useEffect(()=>{
        window.scrollTo(0,0)
    },[jobRoleData])

    return(
        <React.Fragment>
            {/* job role section started ----- */}
            <div className="job-role-details-section">
                <div>
                    <p className="role_chip-para">ROLE</p>
                    <p className="immediate-chip-para">IMMEDIATE JOIN</p>
                </div>

                <div>
                    <h4 className="job-detail-title">{jobRoleData.jobTitle}</h4>

                    <div>
                        <p className="job-detail-location-para-grey">LOCATION</p>
                        <p className="job-detail-location-para">{jobRoleData.jobLocation}</p>
                    </div>

                    <a href="https://forms.gle/cux2RgfbTmroNJSx7" target="_blank" style={{textDecoration:"none"}} className="btn-apply-role">Apply for this Role</a>
                </div>

                <img src="/images/horizontal_divider.svg" alt="" />

                <p className="job-role-about-para">As much as we care about our students we do care for our staff as well. A Job is not just a way to earn a living but it is a lifestyle, and your lifestyle must be appreciated and honored. We at Rinex believe ideas have no limitations and anyone can generate a brilliant idea, we focus to appreciate it. You make us.</p>
            </div>
            {/* job role section ended ----- */}

            {/* job chance section started ----- */}
            <div className="job-chance-section">
                <p className="job-role-para-title">CHANCE</p>
                <h5 className="job-role-heading-title">Your opportunity</h5>
                <p className="job-para-grey">One of the crucial points for our growth is the Inside Sales Strategist we provide to our merchants. Thus far, our customer experience has been the main differentiator between Rinex and our competitors.</p>
                <p className="job-para-grey">As a Inside Sales Strategist, you’ll go the extra mile to make sure our merchants can run their business effectively by supporting them with technical matters focused on, but not limited to, the integration of Rinex products and services.</p>
                <p className="job-para-grey">The Inside Sales Strategist Team is international, multilingual and always ready to go above and beyond for our merchants. When customers have a problem, they listen, analyze, solve and empower. They are true Rinex champions and we hope you will be one of them as well!</p>
            </div>
            {/* job chance section ended ----- */}

            {/* job work section started ----- */}
            <div className="job-work-section">
                <p className="job-role-para-title">WORK</p>
                <h5 className="job-role-heading-title">What you'll be doing</h5>
                <p className="job-para-grey">Advise customer on how to best implement Rinex on their specific setup Be the point of contact for required questions and escalations coming from internet Provide Inside Sales Strategist training to other teams and departments Initiate and contribute to internal documentation.</p>
            </div>
            {/* job work section ended ----- */}


            {/* job for us section started ----- */}
            <div className="job-forus-section">
                <p className="job-role-para-title">FOR US</p>
                <h5 className="job-role-heading-title">What you'll bring</h5>
                <p className="job-para-grey">At our company, we value growth and innovation.</p>
                <p className="job-para-grey">We are always looking for talented and passionate people who can contribute to our mission of creating value for our customers and society.</p>
                <p className="job-para-grey">If you join us, you will have the opportunity to work on challenging and meaningful projects that make a difference.</p>
                <p className="job-para-grey">At our company, we value growth and innovatioYou will also have access to learning and development resources, mentorship and feedback, and a supportive and diverse culture.</p>
                <p className="job-para-grey">You will bring your skills, experience, and enthusiasm to our team, and help us achieve our goals.</p>
                <p className="job-para-grey">You will be part of a company that is constantly evolving and improving, and that rewards excellence and initiative.</p>
            </div>
            {/* job for us section ended ----- */}

            {/* job lets see section started ----- */}
            <div className="job-letssee-section">
                <p className="job-role-para-title">LET'S SEE</p>
                <h5 className="job-role-heading-title">Do you think we are a match?</h5>
                <div className="apply-now-container">
                    <p className="job-para-grey">We're always looking for talent to go on a journey with. So what are you waiting for?</p>

                    <button onClick={btnApplyNowClick} className="btn-apply-now">
                        <div id="apply-button-slider" className="btn-apply-slide-tag">
                            Apply Now
                        </div>
                        <img src="/images/right_arrows.svg" alt="" />
                    </button>
                </div>

                <img src="/images/horizontal_divider.svg" alt="" />
            </div>
            {/* job for us section ended ----- */}


            {/* related job role section started --- */}
            <div className="related-job-section">
                <p className="related-job-role-para">JOB ROLES</p>
                <h5 className="related-job-role-heading">More job openings</h5>

                <div className="related-job-card-container">
                    {
                        relatedRoleData.map((item, index)=>
                            <Link to={`/job/${item.jobTitle}`} className="related-job-card" key={item.id}>
                                <p className="related-card-role-para">ROLE {index+1}</p>
                                <h4 className="related-job-card-heading-title">{item.jobTitle}</h4>
                                <p className="related-job-card-location-para-grey">LOCATION</p>
                                <p className="related-job-card-location-para">{item.jobLocation}</p>

                                <img src="/images/hor_devider.svg" alt="" />

                                <p className="job-apply-para">Apply <img alt="" /></p>
                            </Link>
                        )
                    }
                </div>
            </div>
        </React.Fragment>
    )
}