import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"



export function AboutUs(){

    const containerRef = useRef(null);
    const imageRefs = useRef([]);
    const addToRefs = (el) => {
        if (el && !imageRefs.current.includes(el)) {
            imageRefs.current.push(el);
        }
    };

    useEffect(()=>{
        window.scrollTo(0,0);

        // mouse interaction
        const container = containerRef.current;
        const handleMouseMove = (e) => {
            const { left, top, width, height } = container.getBoundingClientRect();
            const x = (e.clientX - left) / width;
            const y = (e.clientY - top) / height;
            const rotateX = (y - 0.5) * 70; // Adjust the multiplier for more/less tilt
            const rotateY = (x - 0.5) * -70; // Adjust the multiplier for more/less tilt
            imageRefs.current.forEach((image, index) => {
                const directionMultiplier = index % 2 === 0 ? 1 : -1; // Alternating direction
                image.style.transform = `perspective(1000px) rotateX(${rotateX * directionMultiplier}deg) rotateY(${rotateY * directionMultiplier}deg)`;
            });
        };
        const handleMouseLeave = () => {
            imageRefs.current.forEach((image) => {
                image.style.transform = 'perspective(1000px) rotateX(0) rotateY(0)';
            });
        };
        container.addEventListener('mousemove', handleMouseMove);
        container.addEventListener('mouseleave', handleMouseLeave);
        return () => {
            container.removeEventListener('mousemove', handleMouseMove);
            container.removeEventListener('mouseleave', handleMouseLeave);
        };
    },[])

    // parallex effect 
    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => {
        setOffsetY(window.pageYOffset);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
 
    return(
        <React.Fragment>
            {/* about rinex section started ------ */}
            <div className="about-rinex-section">
                <div>
                    <p className="about-rinex-para">ABOUT RINEX</p>
                    <h5 className="about-rinex-heading">Where ideas are talked about and growth is focused upon</h5>
                </div>
                <img style={{ transform: `translateY(${-offsetY * 0.6}px)` }}  src="/images/about-banner.png" alt="" />
            </div>
 
            {/* marquee section started ---- */}
            <div className="marquee-section">
                <div className="scroll-section">
                    <div className="scroll-container">
                        <img src="/images/microsoft_logo.svg" alt="" />
                        <img src="/images/nasscom_logo.svg" alt="" />
                        <img src="/images/adobe_logo.svg" alt="" />
                        <img className="autodesk-logo" src="/images/autodesk_logo.svg" alt="" />
                        <img src="/images/dubai_university_logo.svg" alt="" />
                        <img src="/images/state_university_logo.svg" alt="" />
                        <img src="/images/microsoft_logo.svg" alt="" />
                        <img src="/images/nasscom_logo.svg" alt="" />
                        <img src="/images/adobe_logo.svg" alt="" />
                        <img className="autodesk-logo" src="/images/autodesk_logo.svg" alt="" />
                        <img src="/images/dubai_university_logo.svg" alt="" />
                        <img src="/images/state_university_logo.svg" alt="" />
                        <img src="/images/microsoft_logo.svg" alt="" />
                        <img src="/images/nasscom_logo.svg" alt="" />
                        <img src="/images/adobe_logo.svg" alt="" />
                        <img className="autodesk-logo" src="/images/autodesk_logo.svg" alt="" />
                        <img src="/images/dubai_university_logo.svg" alt="" />
                        <img src="/images/state_university_logo.svg" alt="" />
                        <img src="/images/microsoft_logo.svg" alt="" />
                        <img src="/images/nasscom_logo.svg" alt="" />
                        <img src="/images/adobe_logo.svg" alt="" />
                        <img className="autodesk-logo" src="/images/autodesk_logo.svg" alt="" />
                        <img src="/images/dubai_university_logo.svg" alt="" />
                        <img src="/images/state_university_logo.svg" alt="" />
                        <img src="/images/microsoft_logo.svg" alt="" />
                        <img src="/images/nasscom_logo.svg" alt="" />
                        <img src="/images/adobe_logo.svg" alt="" />
                        <img className="autodesk-logo" src="/images/autodesk_logo.svg" alt="" />
                        <img src="/images/dubai_university_logo.svg" alt="" />
                        <img src="/images/state_university_logo.svg" alt="" />
                    </div>
                </div>
            </div>
             {/* marquee section ended ---- */}

             {/* Little more work section started-----  */}
            <div className="little-more-section">
                <div className="little-more-container">
                    <p className="little-more-para">LITTLE MORE</p>
                    <div className="little-more-head">
                        <h4 className="little-more-heading">Some words about Rinex</h4>
                        <p className="rinex-about-para-grey">Rinex is an online Education platform, providing students with pristine study opportunities and a chance to learn and grow. Started with a vision to solve problems of students and make them job ready or exceptional in their fields. With our extensive programs and courses we try to include excellence in our students. We get students the best study materials and the best teachers, so that you grow three fold.</p>
                    </div>

                    <img src="/images/horizontal_divider.svg" alt="" />

                    <div className="little-more-foot">
                        <h5 className="social-media-heading">Find us on <img src="/images/red_heart.svg" alt="" /> social media</h5>

                        <div>
                            <a href="https://www.linkedin.com/company/rinex-ai/mycompany/" target="_blank" className="social-nav-link"><img src="/images/linkedin_logo.svg" alt="" />LinkedIn <img src="/images/tilt_arrow_grey.svg" alt="" /></a>
                            <a href="mailto:help@rinex.ai"  target="_blank"  className="social-nav-link"><img src="/images/mail_logo.svg" alt="" />Mail <img src="/images/tilt_arrow_grey.svg" alt="" /></a>
                            <a style={{cursor:"not-allowed"}} className="social-nav-link"><img src="/images/twitter_logo.svg" alt="" />X <img src="/images/tilt_arrow_grey.svg" alt="" /></a>
                            <a href="https://www.instagram.com/rinex_ai/"  target="_blank"  className="social-nav-link"><img src="/images/insta_logo.svg" alt="" />Instagram <img src="/images/tilt_arrow_grey.svg" alt="" /></a>
                            <a href="https://www.facebook.com/rinexai/?_rdr"  target="_blank"  className="social-nav-link"><img src="/images/fb_logo.svg" alt="" />Facebook <img src="/images/tilt_arrow_grey.svg" alt="" /></a>
                        </div>
                    </div>

                </div>
            </div>
            {/* Little more work section ended-----  */}

            {/* rinex info card section started ----- */}
            <div className="rinex-info-card-section">
                <div className="rinex-founded-card">
                    <p className="rinex-info-card-para">WHEN IT STARTED</p>
                    <h5 className="rinex-info-card-heading">Rinex was found in 2020</h5>
                    <p className="rinex-info-card-para-grey">Rinex was founded in India, With a vision to be the best and make the best.</p>
                    <div className="founded-card-animation-container">
                        <div className="flag-text">
                            <p>RINEX</p>
                            <p>Flagged at<br></br>2020</p>
                        </div>
                        <img src="/images/founded_card_img.svg" alt="" />
                        <img src="/images/flag.svg" alt="" />
                    </div>
                </div>

                <div className="rinex-numbers-card">
                    <p className="rinex-info-card-para">NUMBERS</p>
                    <h5 className="rinex-info-card-heading">30,000+ Student learned</h5>
                    <p className="rinex-info-card-para-grey">Students who are learning and reaching where they want to go.</p>
                    <img alt="" />
                    <div className="number-card-animation-container">
                        <img alt="" />
                        <img alt="" />
                        <div>
                            <p>30,000+</p>
                            <p>Students learned</p>
                        </div>
                        <img src="/images/big_slider.svg" alt="" />
                        <img src="/images/big_slider.svg" alt="" />
                        <img src="/images/small_slider.svg" alt="" />
                        <img src="/images/small_slider.svg" alt="" />
                    </div>
                </div>

                <div className="rinex-partners-card">
                    <p className="rinex-info-card-para">PARTNERS</p>
                    <h5 className="rinex-info-card-heading">Academic Partners</h5>
                    <p className="rinex-info-card-para-grey">Academic Partners so that we can provide the best to our students.</p>
                    <div className="partner-card-animation-container">
                        <div>
                            <img src="/images/dubai.svg" alt="" />
                            <img src="/images/morgan.svg" alt="" />
                            <img src="/images/dubai.svg" alt="" />
                            <img src="/images/morgan.svg" alt="" />
                            <img src="/images/dubai.svg" alt="" />
                            <img src="/images/morgan.svg" alt="" />
                            <img src="/images/dubai.svg" alt="" />
                            <img src="/images/morgan.svg" alt="" />
                            <img src="/images/dubai.svg" alt="" />
                            <img src="/images/morgan.svg" alt="" />
                            <img src="/images/dubai.svg" alt="" />
                            <img src="/images/morgan.svg" alt="" />
                            <img src="/images/dubai.svg" alt="" />
                            <img src="/images/morgan.svg" alt="" />
                            <img src="/images/dubai.svg" alt="" />
                            <img src="/images/morgan.svg" alt="" />
                            <img src="/images/dubai.svg" alt="" />
                            <img src="/images/morgan.svg" alt="" />
                            <img src="/images/dubai.svg" alt="" />
                        </div>
                        <div>
                            <img src="/images/microsoft.svg" alt="" />
                            <img src="/images/mistral.svg" alt="" />
                            <img src="/images/microsoft.svg" alt="" />
                            <img src="/images/mistral.svg" alt="" />
                            <img src="/images/microsoft.svg" alt="" />
                            <img src="/images/mistral.svg" alt="" />
                            <img src="/images/microsoft.svg" alt="" />
                            <img src="/images/mistral.svg" alt="" />
                            <img src="/images/microsoft.svg" alt="" />
                            <img src="/images/mistral.svg" alt="" />
                            <img src="/images/microsoft.svg" alt="" />
                            <img src="/images/mistral.svg" alt="" />
                            <img src="/images/microsoft.svg" alt="" />
                            <img src="/images/microsoft.svg" alt="" />
                            <img src="/images/mistral.svg" alt="" />
                            <img src="/images/microsoft.svg" alt="" />
                            <img src="/images/mistral.svg" alt="" />
                        </div>
                        <div>
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/state.svg" alt="" />
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/state.svg" alt="" />
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/state.svg" alt="" />
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/state.svg" alt="" />
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/state.svg" alt="" />
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/state.svg" alt="" />
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/state.svg" alt="" />
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/state.svg" alt="" />
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/state.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* rinex info card section ended ----- */}

            {/* features card section started ----- */}
            <div className="features-card-section">
                <div className="online-education-card">
                    <img src="/images/video_logo.svg" alt="" />
                    <p className="feature-card-para">Online Education</p>
                    <p className="feature-card-para-grey">We are one of the leading online education platforms. Teaching and mentoring students to get to their higher potential. We at RineX aim to provide all round solutions to the problems faced by students.</p>
                </div>

                <div className="online-education-card">
                    <img src="/images/click_logo.svg" alt="" />
                    <p className="feature-card-para">Practical Skills</p>
                    <p className="feature-card-para-grey">To bridge the gap between what is being taught and what is needed by the industry. We are here to address major issues by students and catering to all the requirements they have to be the best</p>
                </div>

                <div className="online-education-card">
                    <img src="/images/message_logo.svg" alt="" />
                    <p className="feature-card-para">Support Team</p>
                    <p className="feature-card-para-grey">The Technical Support Team is international, multilingual and always ready to go above and beyond for our merchants.</p>
                </div>
            </div>
            {/* features card section ended ------ */}


            {/* scrollable motivated card started----- */}
            <div className="motivated-card-scroll-section">
                <div className="motivated-card-scroll-container">
                    <div className="motivated-card1">
                        <p className="motivated-card-para">POSITIVITY</p>
                        <h5 className="motivated-card-heading">Dedicated<br></br>Committed<br></br>Success.</h5>
                    </div>

                    <div className="motivated-card2">
                        <p className="motivated-card-para">POSITIVITY</p>
                        <h5 className="motivated-card-heading">Success starts with <br></br>learning.</h5>
                    </div>

                    <div className="motivated-card3">
                        <p className="motivated-card-para">POSITIVITY</p>
                        <h5 className="motivated-card-heading">Success is a<br></br>journey,<br></br>not a destination</h5>
                    </div>

                    <div className="motivated-card4">
                        <p className="motivated-card-para">POSITIVITY</p>
                        <h5 className="motivated-card-heading">Learn, adapt<br></br>succeed.</h5>
                    </div>

                    <div className="motivated-card1">
                        <p className="motivated-card-para">POSITIVITY</p>
                        <h5 className="motivated-card-heading">Dedicated<br></br>Committed<br></br>Success.</h5>
                    </div>

                    <div className="motivated-card2">
                        <p className="motivated-card-para">POSITIVITY</p>
                        <h5 className="motivated-card-heading">Success starts with<br></br> learning</h5>
                    </div>

                    <div className="motivated-card3">
                        <p className="motivated-card-para">POSITIVITY</p>
                        <h5 className="motivated-card-heading">Success is a<br></br>journey,<br></br>not a destination</h5>
                    </div>

                    <div className="motivated-card4">
                        <p className="motivated-card-para">POSITIVITY</p>
                        <h5 className="motivated-card-heading">Learn, adapt<br></br>succeed.</h5>
                    </div>

                    <div className="motivated-card1">
                        <p className="motivated-card-para">POSITIVITY</p>
                        <h5 className="motivated-card-heading">Dedicated<br></br>Committed<br></br>Success.</h5>
                    </div>

                    <div className="motivated-card2">
                        <p className="motivated-card-para">POSITIVITY</p>
                        <h5 className="motivated-card-heading">Success starts with<br></br> learning</h5>
                    </div>

                    <div className="motivated-card3">
                        <p className="motivated-card-para">POSITIVITY</p>
                        <h5 className="motivated-card-heading">Success is a<br></br>journey,<br></br>not a destination</h5>
                    </div>

                    <div className="motivated-card4">
                        <p className="motivated-card-para">POSITIVITY</p>
                        <h5 className="motivated-card-heading">Learn, adapt<br></br>succeed.</h5>
                    </div>

                    <div className="motivated-card1">
                        <p className="motivated-card-para">POSITIVITY</p>
                        <h5 className="motivated-card-heading">Dedicated<br></br>Committed<br></br>Success.</h5>
                    </div>

                    <div className="motivated-card2">
                        <p className="motivated-card-para">POSITIVITY</p>
                        <h5 className="motivated-card-heading">Success starts with<br></br> learning</h5>
                    </div>

                    <div className="motivated-card3">
                        <p className="motivated-card-para">POSITIVITY</p>
                        <h5 className="motivated-card-heading">Success is a<br></br>journey,<br></br>not a destination</h5>
                    </div>

                    <div className="motivated-card4">
                        <p className="motivated-card-para">POSITIVITY</p>
                        <h5 className="motivated-card-heading">Learn, adapt<br></br>succeed.</h5>
                    </div>
                </div>
            </div>
            {/* scrollable motivated card ended----- */}

            {/* work with us section started ------ */}
            <div className="work-with-us-section" ref={containerRef}>
                <div className="work-with-us-container">
                    <div className="work-with-us-left-side">
                        <p className="work-with-us-para">WORK WITH US</p>
                        <h5 className="work-with-us-heading">Driven by Creative Minds</h5>
                        <p className="work-with-us-para-grey">Our commitment and love for service is known to all. Our Alumni and people working with us define us with the best compliments.</p>
                    </div>

                    <Link to="/career" className="btn-view-job-offers-mobile">
                        View all job offers
                    </Link>

                    <div className="work-with-us-right-side">
                        <img ref={addToRefs} src="/images/creative_text.png" alt="" />
                        <img ref={addToRefs} src="/images/work_smart_text.png" alt="" />
                        <img ref={addToRefs} src="/images/culture_text.png" alt="" />
                        <img  ref={addToRefs} src="/images/flower.svg" alt="" />
                        <img  ref={addToRefs} src="/images/red_moon.svg" alt="" />

                        <img className="mobile-text-img" alt="" />
                        <Link to="/career" className="btn-view-job-offers">
                            View all job offers
                        </Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}