import { useState, useEffect } from "react";

const ProspleSearchWidget = () => {
      
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    opportunity_types: "",
    study_fields: "",
    student_name: "",
    college_name: "",
    year: "",
    phone_number: "",
    applying_for: "",
    course: "",
  });

  const [errors, setErrors] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleChange = (e) => {

    const { name, value } = e.target;

    if (name === "phone_number") {
      if (!/^\d*$/.test(value)) return; 
      if (value.length > 10) return;
    }
    
    setFormData({ ...formData, [name]: value });

    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formData.student_name?.trim() || !/^[A-Za-z\s]+$/.test(formData.student_name)) {
      newErrors.student_name = "Enter a valid name (letters & spaces only)";
    }

    if (!formData.college_name?.trim() || !/^[A-Za-z\s]+$/.test(formData.college_name)) {
      newErrors.college_name = "Enter a valid college name (letters & spaces only)";
    }

    if (!formData.year) {
      newErrors.year = "Please select a year";
    }

    if (!formData.phone_number || !/^\d{10}$/.test(formData.phone_number)) {
      newErrors.phone_number = "Enter a valid 10-digit phone number";
    }

    if (formData.phone_number.length === 10 && !/^[6789]\d{9}$/.test(formData.phone_number)) {
        newErrors.phone_number = "Enter a valid  phone number";
    }

    if (!formData.applying_for) {
      newErrors.applying_for = "Please select an option";
    }

    if (!formData.course?.trim()) {
      newErrors.course = "Course field is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    // Uncomment if you want to prevent multiple submissions
    // const savedData = localStorage.getItem("jobFormSubmission");
    // if (savedData) {
    //   alert("You have already submitted the form!");
    // }
  }, []);


  const handleFinalSubmit = () => {

    if (!validateForm()) {
      return;
    }

    console.log("Submitting Data:", formData); // Debugging
   // localStorage.setItem("jobFormSubmission", JSON.stringify(formData));
   //AKfycbxUk0nBzz1_vKDyTcl4DxgPsiBxBVP2AyRgpQdpjweRla8j8Is-E8YVPNrc9njYjfMRoA
    // fetch("", {
    //   method: "POST",
    //   body: JSON.stringify(formData),
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok");
    //     }
    //     return response.json();
    //   })
    //   .then(() => {
    //     alert("Submitted Successfully!");
    //     setTimeout(() => {
    //       setShowModal(false);
    //     }, 500);
    //   })
    //   .catch((err) => {
    //     console.error("Error:", err);
    //     alert("Submission failed! Please try again.");
    //   });

        fetch("https://script.google.com/macros/s/AKfycbxUk0nBzz1_vKDyTcl4DxgPsiBxBVP2AyRgpQdpjweRla8j8Is-E8YVPNrc9njYjfMRoA/exec", {
          method: "POST",
           mode: "no-cors", // Disable CORS check
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            if (data.status === "Error") {
              console.error("Error from API:", data.message);
              alert("Error: " + data.message);
            } else {
              alert("Success: " + data.message);
            }
          })
          .catch((error) => console.error("Fetch error:", error));

          setShowSuccessMessage(true);

          setFormData({
            opportunity_types: "",
            study_fields: "",
            student_name: "",
            college_name: "",
            year: "",
            phone_number: "",
            applying_for: "",
            course: ""
          });
      
          
  };
    
    return (
        <section id="prosple-search-widget" className="making-better-container">
            <style>{`

        #prosple-search-widget .fieldset {
            display: flex;
            justify-content: center !important;
            margin-bottom: 30px !important;
        }

      #prosple-search-widget *,
      #prosple-search-widget::before,
      #prosple-search-widget::after {
        box-sizing: border-box;
      }
      
      #prosple-search-widget form {
        position: relative;
        z-index: 0;
      }
      
      #prosple-search-widget form:last-child {
        margin-bottom: 0px;
      }
      
      #prosple-search-widget form:first-child {
        margin-top: 0px;
      }
      
      #prosple-search-widget .fieldset {
        vertical-align: middle;
        border: 0px none;
        margin: 0px;
        padding: 0px;
      }
      
      #prosple-search-widget .section--input {
        display: inline-block;
        margin: 0 0 20px 0;
        border-radius: 3px;
        background-color:var(--white-bg-color);
        color: initial;
        width: 100%;
      }
      
      #prosple-search-widget .section--input .input-label:first-child {
        margin-top: 0px;
      }
      
      #prosple-search-widget .section--input .input-group {
        border: none;
        padding: 20px;
      }
      
      #prosple-search-widget .section--input .input-group:last-child {
        border-bottom: 0px none;
      }
      
      #prosple-search-widget .section--input .input-label {
        display: block;
        color: rgb(114, 114, 114);
        font-size: 11px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        margin-bottom: 5px;
        padding: 0px 0.5rem;
      }
      
      #prosple-search-widget .section--input .input-group .input {
        border: 0px none;
        display: inline-block;
        margin: 0px;
        position: relative;
        background-color: var(--white-bg-color);
        border-radius: 3px;
        width: 100%;
      }
      
      #prosple-search-widget .section--input select,
      #prosple-search-widget .section--input input[type='text'] {
        font-size: 18px;
        padding: 0.25rem 40px 0.25rem 0.5rem;
        appearance: none;
        -webkit-appearance: none;
        background: transparent none repeat scroll 0% 0%;
        border: 0px none;
        -moz-appearance: none;
        color: rgb(32, 32, 32);
        line-height: 24px;
        position: relative;
        width: 100%;
        z-index: 10;
        display: inline-block;
        font-family: inherit;
        max-width: 100%;
        text-transform: none;
        margin: 0px;
        cursor: pointer;
      }
      
      #prosple-search-widget .section--input .icon {
        color: #28beff;
        position: absolute;
        right: 1em;
        top: 50%;
        transform: translateY(-50%);
        z-index: 5;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        line-height: 1em;
        text-align: center;
        text-decoration: inherit;
        text-transform: none;
        width: 1em;
      }
      
      #prosple-search-widget .section--input .icon--chevron-down::before {
        content: '▼';
        vertical-align: middle;
        box-sizing: border-box;
      }
      
      #prosple-search-widget .input-group--actions {
        text-align: right;
        vertical-align: middle;
        padding: 0;
        margin: 0;
        position: relative;
      }
      
      #prosple-search-widget .input-group--actions .button {
        border-radius: 3px;
        border-style: none;
        font-weight: 500;
        text-decoration: none;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
        font-family: inherit;
        line-height: 20px;
        text-align: center;
        vertical-align: middle;
        background-color: #28beff;
        border: 1px solid transparent;
        color: rgb(255, 255, 255);
        font-size: 14px;
        padding: 10px 16px;
        margin: 0px;
      }
      
      #prosple-search-widget .input-group--actions .button:hover {
        opacity: 0.8;
      }
      
      #prosple-search-widget .input-group--actions .icon {
        font-size: 2em;
        margin-left: 0px;
        margin-right: 0px;
        vertical-align: middle;
        position: relative;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        line-height: 1em;
        text-align: center;
        text-decoration: inherit;
        text-transform: none;
        width: 1em;
      }
      
      #prosple-search-widget .input-group--actions .icon--search::before {
        content: '⚲';
        display: inline-block;
        transform: rotate(-45deg);
      }
      
      #prosple-search-widget .input-group--actions .icon span {
        border: 0px none;
        clip: rect(0px, 0px, 0px, 0px);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0px;
        position: absolute;
        white-space: nowrap;
        width: 1px;
        clip-path: inset(50%);
      }
      
      @media (min-width: 64em) {
        #prosple-search-widget .fieldset {
        display: flex;
        background:var(--white-bg-color);
      }
        #prosple-search-widget .fieldset * {
          vertical-align: middle;
        }
      
        #prosple-search-widget .section--input {
          display: inline-flex;
          margin: 0px;
          border-radius: 3px 0 0 3px;
          height: 90px;
          width: initial;
        }
      
        #prosple-search-widget .section--input .input-group {
          display: inline-block;
          border-bottom: 0px;
          border-right: 1px solid rgb(215, 215, 215);
        }
      
        #prosple-search-widget .section--input .input-group:last-child {
          border-right: 0px none;
        }
      
        #prosple-search-widget .input-group--actions {
          display: inline-block;
        }
      
        #prosple-search-widget .input-group--actions .button {
          height: 91px;
          border-radius: 0px 3px 3px 0px;
        }
      }
        .form-inside{
  margin-top: 20px !important;
  width: 99% !important;
  background-color: var(--header-bg-color);
}

@media (max-width: 768px) {
  #prosple-search-widget .fieldset {
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
  }

  #prosple-search-widget .section--input {
    width: 100%; /* Full width for input fields */
    display: block;
    margin-bottom: 15px;
  }

  #prosple-search-widget .section--input .input-group {
    width: 100%;
    padding: 10px;
  }

  #prosple-search-widget .section--input select,
  #prosple-search-widget .section--input input[type='text'] {
    font-size: 16px;
    padding: 10px;
    width: 100%;
  }

  #prosple-search-widget .input-group--actions {
    text-align: center;
    width: 100%;
  }

  #prosple-search-widget .input-group--actions .button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
  }
}



  /* Modal Background Overlay */
  .modal-overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease-in-out;
    z-index:1;
  }

  /* Modal Content Box */
  .modal-content {
    background: white;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 400px;
    text-align: center;
    animation: scaleIn 0.3s ease-in-out;
    background: url('https://edmingle.b-cdn.net/edmingle_websitebuilder/img/backdrop.png') center/cover no-repeat;
  }

 
  /* Buttons */
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .modal-buttons button {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

    .modal-buttons .cancel {
        background: #667085;
        color: #eeee; 
    }

    .modal-buttons .cancel:hover {
        background: #f28b82; 
    }

  .modal-buttons .submit {
    background: #28beff;
    color: white;
    margin-left: 10px;
  }

  .modal-buttons .submit:hover {
    background: #28beff;
  }

  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(0.9);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  /* Responsive Fix */
  @media (max-width: 480px) {
    .modal-content {
      width: 95%;
    }
  }

  .modal-title{
  margin-bottom:30px
  }

.form-controls {
	appearance: none;
	background-clip: padding-box;
	background-color: #fff;
	border: 1px solid #ced4da;
	border-radius: .375rem;
	color: #667085;
	display: block;
	font-weight: 400;
	line-height: 1.5;
	padding: .375rem .75rem;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	width: 100%;
  margin-bottom: 1.25rem;
  font-size: .875rem;
}

.error-text {
    color: red;
    font-size: 11px;
    margin-top: -15px;
    margin-bottom: 13px;
    font-style: normal;
    font-family: revert;
    letter-spacing: 1px;
    font-size: x-small;
}
.success-message {
    color: green;
    letter-spacing: 1px;
    font-size: 28px;
  }

  @mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.success-pannel {
  background: #28beff;
}
#wrapper {
  width: 600px;
  margin: 0 auto;
  margin-top: 15%;
  padding:20px;
}
  
#wrapper h1 {
  color: #EEE;
  text-shadow: -1px -2px 3px rgba(17, 17, 17, 0.3);
  text-align: center;
  font-family: "Monsterrat", sans-serif;
  font-weight: 909;
  text-transform: uppercase;
  font-size: 56px;
  margin-bottom: 27px;
}
#wrapper  h1 underline {
  border-top: 5px solid rgba(26, 188, 156, 0.3);
  border-bottom: 5px solid rgba(26, 188, 156, 0.3);
}
#wrapper h3 {
  width: 570px;
  margin-left: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  color: #EEE;
}
.modal-buttons-s{
margin-top:20px;
}

            `}</style>
            <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setShowModal(true);
        }}
      >
      <fieldset className="fieldset form-inside">
        <section className="section--input" name="opportunity_types" onChange={handleChange}>
          <div className="input-group">
            <label htmlFor="opportunity_types" className="input-label">
              I'm looking for
            </label>
            <div className="input input--select">
              <select
                id="opportunity_types"
                name="opportunity_types"
                sort="a-z"
              >
                <option value="">Any Opportunity Type</option>
                <option value={"Internship, Clerkship or Placement"}>Internship, Clerkship or Placement</option>
                <option value={"Graduate Job or Program"}>Graduate Job or Program</option>
                <option value={"Apprenticeship or Traineeship"}>Apprenticeship or Traineeship</option>
                <option value={"Virtual Experience"}>Virtual Experience</option>
                <option value={"Part-Time Student Job"}>Part-Time Student Job</option>
                <option value={"Volunteering"}>Volunteering</option>
                <option value={"Virtual Work Experience"}>Virtual Work Experience</option>
              </select>
              <span className="icon icon--chevron-down" />
            </div>
          </div>
          <div className="input-group">
            <label htmlFor="study_fields" className="input-label">
              I'm studying
            </label>
            <div className="input input--select">
              <select id="study_fields"  sort="a-z" name="study_fields" onChange={handleChange}>
                <option value="">Any Study Field</option>
                <option value={"Business & Management"}>Business &amp; Management</option>
                <option value={"Engineering & Mathematics"}>Engineering &amp; Mathematics</option>
                <option value={"IT & Computer Science"}>IT &amp; Computer Science</option>
                <option value={"Creative Arts"}>Creative Arts</option>
                <option value={"Sciences"}>Sciences</option>
                <option value={"Medical & Health Sciences"}>Medical &amp; Health Sciences</option>
                <option value={"Property & Built Environment"}>Property &amp; Built Environment</option>
                <option value={'Humanities, Arts & Social Sciences'}>
                  Humanities, Arts &amp; Social Sciences
                </option>
                <option value={"Law, Legal Studies & Justice"}>Law, Legal Studies &amp; Justice</option>
                <option value={"Teaching & Education"}>Teaching &amp; Education</option>
                <option value={"Food, Hospitality & Personal Services"}>
                  Food, Hospitality &amp; Personal Services
                </option>
                <option value={"Trades & Services"}>Trades &amp; Services</option>
              </select>
              <span className="icon icon--chevron-down" />
            </div>
          </div>
        </section>
        <div className="input-group input-group--actions">
          <button type="submit" value="Submit" className="button">
            <span className="icon icon--search">
              <span>Search</span>
            </span>
          </button>
        </div>
      </fieldset>
    </form>

    {showModal && (
      <div className="modal-overlay making-better-container">
        <div className="modal-content">
          <h2 className="modal-title">Enter Your Details</h2>

          {showSuccessMessage ? (
          <div id="wrapper" className="animated zoomIn success-pannel">
          <h1>
          <underline>Thank you!</underline>
          </h1>
          <h3>
          Our team will contact you for more assistance!
          </h3>


          <div className="modal-buttons modal-buttons-s">
          <button onClick={() => setShowModal(false)} className="cancel">Close</button>
          </div>

          </div>
          ) : (
            <>
              <div>
                <input type="text" name="student_name" placeholder="Student Name" className="form-controls" onChange={handleChange} value={formData.student_name} />
                {errors.student_name && <p className="error-text">{errors.student_name}</p>}
              </div>

              <div>
                <input type="text" name="college_name" placeholder="College Name" className="form-controls" onChange={handleChange} value={formData.college_name} />
                {errors.college_name && <p className="error-text">{errors.college_name}</p>}
              </div>

              <div>
                <select name="year" className="form-controls" onChange={handleChange} value={formData.year}>
                  <option value="">Select Year</option>
                  <option value="1st Year">1st Year</option>
                  <option value="2nd Year">2nd Year</option>
                  <option value="3rd Year">3rd Year</option>
                  <option value="4th Year">4th Year</option>
                </select>
                {errors.year && <p className="error-text">{errors.year}</p>}
              </div>

              <div>
                <input type="tel" name="phone_number" placeholder="Phone Number" className="form-controls" onChange={handleChange} value={formData.phone_number} />
                {errors.phone_number && <p className="error-text">{errors.phone_number}</p>}
              </div>

              <div>
                <select name="applying_for" className="form-controls" onChange={handleChange} value={formData.applying_for}>
                  <option value="">Applying For</option>
                  <option value="Placement">Placement</option>
                  <option value="Internships">Internships</option>
                </select>
                {errors.applying_for && <p className="error-text">{errors.applying_for}</p>}
              </div>

              <div>
                <select name="course" className="form-controls" onChange={handleChange} value={formData.course}>
                  <option value="">Applying Course</option>
                  <option value="Cyber Security with ethical hacking">Cyber Security with ethical hacking</option>
                  <option value="Web Development Full Stack ">Web Development Full Stack </option>

                  <option value="Machine Learning">Machine Learning</option>
                  <option value="Artificial Intelligence">Artificial Intelligence</option>

                  <option value="Internet of Things & Robotics">Internet of Things & Robotics</option>
                  <option value="AutoCAD & MATLAB">AutoCAD & MATLAB</option>

                  <option value="Data Science">Data Science</option>
                  <option value="DSA WITH C++">DSA with C++</option>

                  <option value="DSA WITH Python">DSA with Python</option>
                  <option value="DSA WITH JAVA">DSA with JAVA</option>
                </select>
                {errors.course && <p className="error-text">{errors.course}</p>}
              </div>


              {/* Buttons */}
              <div className="modal-buttons">
                <button onClick={() => setShowModal(false)} className="cancel">Cancel</button>
                <button onClick={handleFinalSubmit} className="submit">Submit</button>
              </div>
            </>
          )}
        </div>
      </div>
    )}
  </div>
        </section>
    );
};

export default ProspleSearchWidget;
