import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export function Home(props) {
  const [onlineCourseData, setOnlineCourseData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setOnlineCourseData([
      {
        id: 4,
        courseType: "TRENDING . CS",
        courseHeding: "Machine Learning",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img1.png",
      },
      {
        id: 3,
        courseType: "TRENDING . CS",
        courseHeding: "Data Science",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img2.png",
      },
      {
        id: 1,
        courseType: "TRENDING . CS",
        courseHeding: "Artificial Intelligence",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img3.png",
      },
      {
        id: 9,
        courseType: "TRENDING . CS",
        courseHeding: "Microsoft Azure",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img4.png",
      },
      {
        id: 10,
        courseType: "TRENDING . CS",
        courseHeding: "Python",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img5.png",
      },
    ]);
  }, []);

  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => {
    setOffsetY(window.pageYOffset);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function handleTrendingCourse() {
    setOnlineCourseData([
      {
        id: 4,
        courseType: "TRENDING . CS",
        courseHeding: "Machine Learning",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img1.png",
      },
      {
        id: 3,
        courseType: "TRENDING . CS",
        courseHeding: "Data Science",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img2.png",
      },
      {
        id: 1,
        courseType: "TRENDING . CS",
        courseHeding: "Artificial Intelligence",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img3.png",
      },
      {
        id: 9,
        courseType: "TRENDING . CS",
        courseHeding: "Microsoft Azure",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img4.png",
      },
      {
        id: 10,
        courseType: "TRENDING . CS",
        courseHeding: "Python",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img5.png",
      },
    ]);
  }

  function handleAllCourse() {
    setOnlineCourseData([
      {
        id: 3,
        courseType: "ALL . CS",
        courseHeding: "Data Science",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img2.png",
      },
      {
        id: 4,
        courseType: "ALL . CS",
        courseHeding: "Machine Learning",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img1.png",
      },
      {
        id: 1,
        courseType: "ALL . CS",
        courseHeding: "Artificial Intelligence",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img3.png",
      },
      {
        id: 9,
        courseType: "ALL . CS",
        courseHeding: "Microsoft Azure",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img4.png",
      },
      {
        id: 10,
        courseType: "ALL . CS",
        courseHeding: "Python",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img1.png",
      },
      {
        id: 2,
        courseType: "ALL . CS",
        courseHeding: "Cyber Security",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img2.png",
      },
      {
        id: 5,
        courseType: "ALL . CS",
        courseHeding: "Web Development",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img3.png",
      },
      {
        id: 6,
        courseType: "ALL . CIVIL",
        courseHeding: "AutoCad",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img4.png",
      },
      {
        id: 7,
        courseType: "ALL . EXTC",
        courseHeding: "Internet Of Things",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img5.png",
      },
      {
        id: 8,
        courseType: "ALL . MECHNICAL",
        courseHeding: "AutoCad",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img1.png",
      },
    ]);
  }

  function handleCoumputerScienceCourse() {
    setOnlineCourseData([
      {
        id: 1,
        courseType: "Computer Science . CS",
        courseHeding: "Artificial Intelligence",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img3.png",
      },
      {
        id: 4,
        courseType: "Computer Science . CS",
        courseHeding: "Machine Learning",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img1.png",
      },
      {
        id: 3,
        courseType: "Computer Science . CS",
        courseHeding: "Data Science",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img2.png",
      },
      {
        id: 9,
        courseType: "Computer Science . CS",
        courseHeding: "Microsoft Azure",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img4.png",
      },
      {
        id: 10,
        courseType: "Computer Science . CS",
        courseHeding: "Python",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img5.png",
      },
    ]);
  }

  function handleElectricalCourse() {
    setOnlineCourseData([
      {
        id: 7,
        courseType: "ALL . EXTC",
        courseHeding: "Internet Of Things",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img5.png",
      },
    ]);
  }

  function handleMechanicalCourse() {
    setOnlineCourseData([
      {
        id: 8,
        courseType: "ALL . MECHNICAL",
        courseHeding: "AutoCad",
        courseDuration: "2 Months",
        programMode: "Live and Interactive",
        cardImage: "/images/course_card_img1.png",
      },
    ]);
  }

  function handleOnlineCourse(e) {
    if (e.target.value === "trending") {
      setOnlineCourseData([
        {
          id: 4,
          courseType: "TRENDING . CS",
          courseHeding: "Machine Learning",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img1.png",
        },
        {
          id: 3,
          courseType: "TRENDING . CS",
          courseHeding: "Data Science",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img2.png",
        },
        {
          id: 1,
          courseType: "TRENDING . CS",
          courseHeding: "Artificial Intelligence",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img3.png",
        },
        {
          id: 9,
          courseType: "TRENDING . CS",
          courseHeding: "Microsoft Azure",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img4.png",
        },
        {
          id: 10,
          courseType: "TRENDING . CS",
          courseHeding: "Python",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img5.png",
        },
      ]);
    } else if (e.target.value === "all") {
      setOnlineCourseData([
        {
          id: 3,
          courseType: "ALL . CS",
          courseHeding: "Data Science",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img2.png",
        },
        {
          id: 4,
          courseType: "ALL . CS",
          courseHeding: "Machine Learning",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img1.png",
        },
        {
          id: 1,
          courseType: "ALL . CS",
          courseHeding: "Artificial Intelligence",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img3.png",
        },
        {
          id: 9,
          courseType: "ALL . CS",
          courseHeding: "Microsoft Azure",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img4.png",
        },
        {
          id: 10,
          courseType: "ALL . CS",
          courseHeding: "Python",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img1.png",
        },
        {
          id: 2,
          courseType: "ALL . CS",
          courseHeding: "Cyber Security",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img2.png",
        },
        {
          id: 5,
          courseType: "ALL . CS",
          courseHeding: "Web Development",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img3.png",
        },
        {
          id: 6,
          courseType: "ALL . CIVIL",
          courseHeding: "AutoCad",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img4.png",
        },
        {
          id: 7,
          courseType: "ALL . EXTC",
          courseHeding: "Internet Of Things",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img5.png",
        },
        {
          id: 8,
          courseType: "ALL . MECHNICAL",
          courseHeding: "AutoCad",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img1.png",
        },
      ]);
    } else if (e.target.value === "cs") {
      setOnlineCourseData([
        {
          id: 1,
          courseType: "Computer Science . CS",
          courseHeding: "Artificial Intelligence",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img3.png",
        },
        {
          id: 4,
          courseType: "Computer Science . CS",
          courseHeding: "Machine Learning",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img1.png",
        },
        {
          id: 3,
          courseType: "Computer Science . CS",
          courseHeding: "Data Science",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img2.png",
        },
        {
          id: 9,
          courseType: "Computer Science . CS",
          courseHeding: "Microsoft Azure",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img4.png",
        },
        {
          id: 10,
          courseType: "Computer Science . CS",
          courseHeding: "Python",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img5.png",
        },
      ]);
    } else if (e.target.value === "extc") {
      setOnlineCourseData([
        {
          id: 7,
          courseType: "ALL . EXTC",
          courseHeding: "Internet Of Things",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img5.png",
        },
      ]);
    } else if (e.target.value === "mech") {
      setOnlineCourseData([
        {
          id: 8,
          courseType: "ALL . MECHNICAL",
          courseHeding: "AutoCad",
          courseDuration: "2 Months",
          programMode: "Live and Interactive",
          cardImage: "/images/course_card_img1.png",
        },
      ]);
    }
  }

  return (
    <React.Fragment>
      {/* hero section started ------ */}
      <div className="hero-section transparent-bg">
        <div className="hero-section-left-side">
          <p className="hero-section-para">BECOME A PART OF SKILL HUB</p>
          <h1 className="hero-section-heading">
            Join the student network of skill India
          </h1>

          <div className="section-enroll-container">
            <a
              onMouseOver={props.addSmoothScrolling}
              onMouseOut={props.removeSmoothScrolling}
              style={{ textDecoration: "none" }}
              href="#onlineCourse"
              className="btn-enroll"
            >
              Enroll Now
              <img src="/images/enroll_icon.svg" alt="" />
            </a>
            <p className="hero-section-enroll-para">
              Enrolled by <span>2L+ Students</span>
            </p>
          </div>

          {/* <div className="hero-section-logo">
                        <img alt="" />
                        <img alt="" />
                        <img alt="" />
                    </div> */}
        </div>

        <div className="hero-section-right-side">
          <img
            loading="lazy"
            className="hero-image-1"
            src="/images/hero_absolute_image.png"
            alt=""
          />
          <img
            loading="lazy"
            className="natash-pic"
            src="/images/natasha_image.png"
            alt=""
          />
          <img
            loading="lazy"
            className="hero-image-2"
            style={{ transform: `translateY(${-offsetY * 0.2}px)` }}
            src="/images/hero_image2.png"
            alt=""
          />
          <img
            loading="lazy"
            className="enroll-chip"
            style={{ transform: `translateY(${-offsetY * 0.2}px)` }}
            src=""
            alt=""
          />
          <img
            loading="lazy"
            className="google-chip"
            style={{ transform: `translateY(${-offsetY * 0.2}px)` }}
            alt=""
          />
          <img
            loading="lazy"
            className="google-certificate-chip"
            style={{ transform: `translateY(${-offsetY * 0.3}px)` }}
            alt=""
          />
        </div>

        {/* <div className="hero-section-logo-mobile">
                        <img src="/images/nsdc_logo_mobile.svg" alt="" />
                        <img src="/images/skillIndia_logo_mobile.svg" alt="" />
                        <img src="/images/internship_logo.svg" alt="" />
                    </div> */}
      </div>
      {/* hero section endedn ------ */}

      {/* marquee section started ---- */}
      <div className="marquee-section">
        <div className="scroll-section">
          <div className="scroll-container">
            <img src="/images/adobe_logo.svg" alt="" />
            <img
              src="/images/microsoft_logo.svg"
              className="Microsoft-Logo"
              alt=""
            />
            <img src="/images/nasscom_logo.svg" alt="" />
            <img
              className="google-logo"
              src="/images/googleForEducation.png"
              alt=""
            />
            <img
              className="autodesk-logo"
              src="/images/autodesk_logo.svg"
              alt=""
            />
            <img src="/images/nsdc_logo_mobile.svg" alt="" />
            <img src="/images/skillIndia_logo_mobile.svg" alt="" />
            <img src="/images/internship_logo.svg" alt="" />
            <img src="/images/adobe_logo.svg" alt="" />
            <img
              src="/images/microsoft_logo.svg"
              className="Microsoft-Logo"
              alt=""
            />
            <img src="/images/nasscom_logo.svg" alt="" />
            <img
              className="google-logo"
              src="/images/googleForEducation.png"
              alt=""
            />
            <img
              className="autodesk-logo"
              src="/images/autodesk_logo.svg"
              alt=""
            />
            <img src="/images/nsdc_logo_mobile.svg" alt="" />
            <img src="/images/skillIndia_logo_mobile.svg" alt="" />
            <img src="/images/internship_logo.svg" alt="" />
            <img src="/images/adobe_logo.svg" alt="" />
            <img
              src="/images/microsoft_logo.svg"
              className="Microsoft-Logo"
              alt=""
            />
            <img src="/images/nasscom_logo.svg" alt="" />
            <img
              className="google-logo"
              src="/images/googleForEducation.png"
              alt=""
            />
            <img
              className="autodesk-logo"
              src="/images/autodesk_logo.svg"
              alt=""
            />
            <img src="/images/nsdc_logo_mobile.svg" alt="" />
            <img src="/images/skillIndia_logo_mobile.svg" alt="" />
            <img src="/images/internship_logo.svg" alt="" />
            <img src="/images/adobe_logo.svg" alt="" />
            <img
              src="/images/microsoft_logo.svg"
              className="Microsoft-Logo"
              alt=""
            />
            <img src="/images/nasscom_logo.svg" alt="" />
            <img
              className="google-logo"
              src="/images/googleForEducation.png"
              alt=""
            />
            <img
              className="autodesk-logo"
              src="/images/autodesk_logo.svg"
              alt=""
            />
            <img src="/images/nsdc_logo_mobile.svg" alt="" />
            <img src="/images/skillIndia_logo_mobile.svg" alt="" />
            <img src="/images/internship_logo.svg" alt="" />
            <img src="/images/adobe_logo.svg" alt="" />
            <img
              src="/images/microsoft_logo.svg"
              className="Microsoft-Logo"
              alt=""
            />
            <img src="/images/nasscom_logo.svg" alt="" />
            <img
              className="google-logo"
              src="/images/googleForEducation.png"
              alt=""
            />
            <img
              className="autodesk-logo"
              src="/images/autodesk_logo.svg"
              alt=""
            />
            <img src="/images/nsdc_logo_mobile.svg" alt="" />
            <img src="/images/skillIndia_logo_mobile.svg" alt="" />
            <img src="/images/internship_logo.svg" alt="" />
            <img src="/images/adobe_logo.svg" alt="" />
            <img
              src="/images/microsoft_logo.svg"
              className="Microsoft-Logo"
              alt=""
            />
            <img src="/images/nasscom_logo.svg" alt="" />
            <img
              className="google-logo"
              src="/images/googleForEducation.png"
              alt=""
            />
            <img
              className="autodesk-logo"
              src="/images/autodesk_logo.svg"
              alt=""
            />
            <img src="/images/nsdc_logo_mobile.svg" alt="" />
            <img src="/images/skillIndia_logo_mobile.svg" alt="" />
            <img src="/images/internship_logo.svg" alt="" />
            <img src="/images/adobe_logo.svg" alt="" />
            <img
              src="/images/microsoft_logo.svg"
              className="Microsoft-Logo"
              alt=""
            />
            <img src="/images/nasscom_logo.svg" alt="" />
            <img
              className="google-logo"
              src="/images/googleForEducation.png"
              alt=""
            />
            <img
              className="autodesk-logo"
              src="/images/autodesk_logo.svg"
              alt=""
            />
            <img src="/images/nsdc_logo_mobile.svg" alt="" />
            <img src="/images/skillIndia_logo_mobile.svg" alt="" />
            <img src="/images/internship_logo.svg" alt="" />
          </div>
        </div>
      </div>
      {/* marquee section ended ---- */}

      <div id="partnerNav"></div>

      {/* partnership section started --- */}
      <div className="partnership-section" style={{ overflow: "hidden" }}>
        <div className="partnership-card" style={{ overflow: "hidden" }}>
          <p className="partnership-card-para">PARTNERSHIP</p>
          <h2 className="partnership-card-heading">
            In Partnership with Google for Education
          </h2>
          <p className="partnership-card-para-grey"></p>

          <div
            className="partnership-card-rinex-tag"
            data-aos-duration="1000"
            data-aos="fade-right"
          >
            <img alt="" />
          </div>
          <div className="partnership-card-handshake-tag">
            <img alt="" />
          </div>
          <div
            className="partnership-card-iit-tag"
            data-aos-duration="1000"
            data-aos="fade-left"
          >
            <img alt="" />
          </div>
        </div>
        <div className="recognition-card">
          <p className="partnership-card-para">RECOGNITION</p>
          <h5 className="recognition-card-heading">
            Recognized and Certified by the Nation
          </h5>
        </div>
      </div>
      {/* partnership section ended --- */}

      <div id="onlineCourse"></div>

      {/* online course section started ------- */}
      <div className="online-course-section">
        <div className="online-course-head-container">
          <p className="online-course-para">COURSE</p>
          <h3 className="online-course-heading">
            Our Online Training Programs
          </h3>
          <p className="online-course-para-grey">
            All courses has certified Certificate partners.
          </p>
        </div>

        <div className="course-category-container">
          <div>
            <button id="btnTrending" onClick={handleTrendingCourse}>
              <img alt="" />
              Trending
            </button>
            <button id="btnAll" onClick={handleAllCourse}>
              All
            </button>
            <button id="btnCS" onClick={handleCoumputerScienceCourse}>
              <img src="/images/cs_icon.svg" alt="" />
              Computer Science
            </button>
            <button id="btnExtc" onClick={handleElectricalCourse}>
              <img src="/images/extc_icon.svg" alt="" />
              Electrical
            </button>
            <button id="btnMech" onClick={handleMechanicalCourse}>
              <img src="/images/mech_icon.svg" alt="" />
              Mechanical
            </button>
            <button id="btnOther">
              <img src="/images/dots.svg" alt="" />
              Other
            </button>
          </div>

          <div>
            <select onChange={handleOnlineCourse}>
              <option value="trending">Trending</option>
              <option value="all">All</option>
              <option value="cs">Computer Science</option>
              <option value="extc">Electrical</option>
              <option value="mech">Mechanical</option>
            </select>
          </div>

          {/* <div>
                        <a id="btnMore">
                            More <img src="/images/right_arrow.svg"  alt=""/>
                        </a>
                    </div> */}
        </div>

        <p className="course-para-grey">
          {onlineCourseData.length} TRENDING COURSES FOUND
        </p>

        <div className="course-cards-container">
          {onlineCourseData.map((item) => (
            <Link
              to={`/program/${item.id}`}
              key={item.id}
              className="course-card"
            >
              <img
                className="online-course-offer-chip"
                src="/images/online_course_offer_chip.svg"
                alt=""
              />
              <div
                className="course-card-head"
                style={{ background: `url(${item.cardImage})` }}
              >
                <p className="course-card-type">{item.courseType}</p>
                <h5 className="course-card-heading">{item.courseHeding}</h5>
              </div>
              <div className="course-card-body">
                <p className="card-body-para-grey">DURATION</p>
                <p className="card-body-para-black">
                  {item.courseDuration} . Project included
                </p>
                <p className="card-body-para-grey">PROGRAM</p>
                <p className="card-body-para-black">
                  {item.programMode}
                  <img className="info-chip" />
                </p>
                <div id="info-chip">Info text</div>
              </div>
              <div className="course-card-foot">
                <div style={{ textAlign: "center" }}>
                  <img src="/images/card_divider.svg" alt="" />
                </div>
                <p className="view-program-para">
                  View Program <img src="/images/tilt_arrow_light.svg" alt="" />
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
      {/* online course section ended ------- */}

      {/* Experts section started ---- */}
      <div className="expert-section">
        <div className="expert-container">
          <div className="expert-container-left-side">
            <p className="expert-section-para">EXPERTS</p>
            <h5 className="expert-section-heading">
              Industry Professionals From
            </h5>
            <p className="expert-section-para-grey">
              Get better knowledge from our best industry experts from the best
              of the companies.
            </p>
          </div>
          <div className="expert-container-right-side">
            <img
              data-aos="fade-left"
              data-aos-duration="2000"
              src="/images/logo_row_1.png"
              alt=""
            />
            <img
              data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-delay="200"
              src="/images/logo_row_2.png"
              alt=""
            />
            <img
              data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-delay="300"
              src="/images/logo_row_3.png"
              alt=""
            />
            <img
              data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-delay="700"
              src="/images/expert_bg.png"
              alt=""
            />
          </div>
        </div>
      </div>
      {/* Experts section ended ---- */}

      {/* Experts card section started --- */}
      <div className="expert-card-section">
        <div className="global-card">
          <p className="global-card-para">GLOBAL</p>
          <h5 className="global-card-heading">
            Studied by lots of students around the globe.
          </h5>
        </div>

        <div className="liked-card">
          <p className="liked-card-para">LIKED</p>
          <h5 className="liked-card-heading">4.5+ Rating for our courses</h5>
          <p className="liked-card-rating-para">
            4.5+ <span> &nbsp; Google Reviews</span>
          </p>
          <div className="liked-card-animated-section">
            <h3>4.5+</h3>
            <img src="/images/star_small_1.svg" alt="" />
            <img src="/images/star_big.svg" alt="" />
            <img src="/images/star_small_1.svg" alt="" />
          </div>
          <p className="liked-card-rating-para2">
            4.5+ <span> &nbsp; Google Reviews</span>
          </p>
        </div>

        <div className="offer-card">
          <p className="offer-card-para">OFFERS</p>
          <h2 className="offer-card-heading">
            Awesome discounts on all courses
          </h2>
          <p className="offer-card-para-grey">
            <b>₹500 OFF</b> on every courses, Get start for owning a new skill.
          </p>

          <div className="offer-card-tag-1">
            <img src="/images/yellow_tag.svg" alt="" />
            <img src="/images/purple_tag.svg" alt="" />
            <img src="/images/offer_tag.svg" alt="" />
          </div>
        </div>
      </div>
      {/* Expert card section ended ----- */}

      <div id="demoNav"></div>

      {/* free courses section started -------- */}
      <div className="free-course-section">
        <div className="free-course-head-container">
          <p className="free-course-para">COURSES</p>
          <h4 className="free-course-heading">Explore our Free programs</h4>
          <div className="grey-para-container">
            <p className="free-course-para-grey">
              All courses has certified Certificate partners.
            </p>
            <a id="btnViewMore">
              View more <img src="/images/right_arrow.svg" alt="" />
            </a>
          </div>
        </div>

        <div className="free-course-card-container">
          <Link to="/freeprogram/1" className="free-course-card">
            <img
              className="free-course-offer-chip"
              src="/images/free_course_offer_chip.svg"
              alt=""
            />
            <div className="color-line-1"></div>
            <div className="free-course-card-content">
              <p className="free-course-card-para">FREE COURSE . CS</p>
              <h4 className="free-course-card-heading">Machine Learning</h4>
              <p className="free-course-duration-para-grey1">DURATION</p>
              <p className="free-course-duration-para-black1">
                3 Months · Project included
              </p>

              <p className="free-course-duration-para-grey2">PROGRAM</p>
              <p className="free-course-duration-para-black2">Self placed</p>
            </div>

            <div style={{ textAlign: "center" }}>
              <img src="/images/card_divider.svg" alt="" />
            </div>
            <p className="free-course-view-program-para">
              View Program <img src="/images/tilt_arrow_light.svg" alt="" />
            </p>
          </Link>

          <Link to="/freeprogram/2" className="free-course-card">
            <img
              className="free-course-offer-chip"
              src="/images/free_course_offer_chip.svg"
              alt=""
            />
            <div className="color-line-2"></div>
            <div className="free-course-card-content">
              <p className="free-course-card-para">FREE COURSE . CS</p>
              <h4 className="free-course-card-heading">
                Artificial Intelligence
              </h4>
              <p className="free-course-duration-para-grey1">DURATION</p>
              <p className="free-course-duration-para-black1">
                3 Months · Project included
              </p>

              <p className="free-course-duration-para-grey2">PROGRAM</p>
              <p className="free-course-duration-para-black2">Self placed</p>
            </div>

            <div style={{ textAlign: "center" }}>
              <img src="/images/card_divider.svg" alt="" />
            </div>
            <p className="free-course-view-program-para">
              View Program <img src="/images/tilt_arrow_light.svg" alt="" />
            </p>
          </Link>

          <Link to="/freeprogram/3" className="free-course-card">
            <img
              className="free-course-offer-chip"
              src="/images/free_course_offer_chip.svg"
              alt=""
            />
            <div className="color-line-3"></div>
            <div className="free-course-card-content">
              <p className="free-course-card-para">FREE COURSE . CS</p>
              <h4 className="free-course-card-heading">Data Science</h4>
              <p className="free-course-duration-para-grey1">DURATION</p>
              <p className="free-course-duration-para-black1">
                3 Months · Project included
              </p>

              <p className="free-course-duration-para-grey2">PROGRAM</p>
              <p className="free-course-duration-para-black2">Self placed</p>
            </div>

            <div style={{ textAlign: "center" }}>
              <img src="/images/card_divider.svg" alt="" />
            </div>
            <p className="free-course-view-program-para">
              View Program <img src="/images/tilt_arrow_light.svg" alt="" />
            </p>
          </Link>
        </div>
      </div>
      {/* free courses section ended -------- */}
      <div id="reviewNav"></div>

      {/* Feedback section started -------- */}
      <div className="feedback-section">
        <div className="feedback-head-container">
          <p className="feedback-para">FEEDBACK</p>
          <h4 className="feedback-heading">Trusted by 50K+ Users</h4>
          <div className="feedback-grey-para-container">
            <p className="feedback-para-grey">
              Trusted by our community of over 50K+ users.
            </p>
            <img src="/images/google_review_chip.svg" alt="" />
          </div>
        </div>

        <div className="feedback-card-container">
          <div className="feedback-card">
            <p className="feedback-message">
              Rinex Technologies Private Limited excels in innovative software
              solutions and IT services, delivering high-quality, client-focused
              products. Their expert team ensures customer satisfaction, making
              Rinex a reliable partner in the tech industry.
            </p>
            <div className="feedback-card-foot">
              <div className="personImg">
                <img src="/images/testimonials/person1.jpg" alt="" />
              </div>
              <div className="feedback-profile">
                <h3>Vignesh S</h3>
                <p>Sri Shakti College</p>
              </div>
            </div>
          </div>
          <div className="feedback-card">
            <p className="feedback-message">
              Rinex, It's very easy to learn and topics& all courses is
              available what people is trying to learn and budget friendly to
              learn the courses . Thank u Rinex
            </p>
            <div className="feedback-card-foot">
              <div className="personImg">
                <img src="/images/testimonials/person2.jpg" alt="" />
              </div>
              <div className="feedback-profile">
                <h3>Avala Gurumurthy</h3>
                <p>Vijayam Science and Arts Degree College </p>
              </div>
            </div>
          </div>
          <div className="feedback-card">
            <p className="feedback-message">
              It is a quality course. Keep going, we need this type of course to
              improve our studies. Excellent. Thank you!
            </p>
            <div className="feedback-card-foot">
              <div className="personImg">
                <img src="/images/testimonials/person3.jpg" alt="" />
              </div>
              <div className="feedback-profile">
                <h3>Prathiyumnan Palanisamy</h3>
                <p>Anna University</p>
              </div>
            </div>
          </div>
          <div className="feedback-card">
            <p className="feedback-message">
              I gained a lot from the WDC course, learning web development from
              excellent faculty who honed my skills and made me a professional
              developer. I can now create static web pages using HTML and CSS,
              select an appropriate web hosting service, and publish my webpages
              for the world to see.
            </p>
            <div className="feedback-card-foot">
              <div className="personImg">
                <img src="/images/testimonials/person4.jpg" alt="" />
              </div>
              <div className="feedback-profile">
                <h3>Jalla Venkata Sivananda</h3>
                <p>Sri Venkateshwara College of Engineering and Technology</p>
              </div>
            </div>
          </div>
          <div className="feedback-card">
            <p className="feedback-message">
              Choosing RINEX for my internship was a great decision, as I gained
              a lot of knowledge and learned many new things. The mentors were
              friendly and collaborative, making it a valuable opportunity for
              my future. Thank you, and I'm pleased to be part of this company.
            </p>
            <div className="feedback-card-foot">
              <div className="personImg">
                <img src="/images/testimonials/person5.jpg" alt="" />
              </div>
              <div className="feedback-profile">
                <h3>Nayanori Jahnavi</h3>
                <p>Sri Venkateshwara College of Engineering and Technology</p>
              </div>
            </div>
          </div>
          <div className="feedback-card">
            <p className="feedback-message">
              RINEX is a good option toy internship. I feel very good to join in
              this internship. I have learn more from this rinex, thank you.
            </p>
            <div className="feedback-card-foot">
              <div className="personImg">
                <img src="/images/testimonials/person6.jpg" alt="" />
              </div>
              <div className="feedback-profile">
                <h3>Rasineni Kavyanandini</h3>
                <p>Sri Venkateshwara College of Engineering and Technology</p>
              </div>
            </div>
          </div>
          <div className="feedback-card">
            <p className="feedback-message">
              Rinex is a robust and user-friendly platform for managing tasks,
              projects, and collaborations, with an intuitive interface and
              customizable workflows. Its powerful integrations with other tools
              enhance productivity and simplify complex workflows, making it
              highly regarded in various work environments.
            </p>
            <div className="feedback-card-foot">
              <div className="personImg">
                <img src="/images/testimonials/person7.jpg" alt="" />
              </div>
              <div className="feedback-profile">
                <h3>Alla Mokshith Naga Sai</h3>
                <p>Vignan University</p>
              </div>
            </div>
          </div>
          <div className="feedback-card">
            <p className="feedback-message">
              I recently completed the "Introduction to Data Science" course,
              which exceeded my expectations with its comprehensive content,
              practical examples, and hands-on projects. The knowledgeable
              instructors and well-paced structure significantly boosted my
              confidence in data science, making it an enriching experience I
              highly recommend to anyone starting in this field.
            </p>
            <div className="feedback-card-foot">
              <div className="personImg">
                <img src="/images/testimonials/person8.jpg" alt="" />
              </div>
              <div className="feedback-profile">
                <h3>Vinay Kumar</h3>
                <p>Alliance University</p>
              </div>
            </div>
          </div>
          <div className="feedback-card">
            <p className="feedback-message">
              The course curriculum is on par with leading industry standards,
              providing a wide range of courses that cater to various interests
              and skill levels. It's definitely worth giving a try for anyone
              looking to advance their knowledge and career.
            </p>
            <div className="feedback-card-foot">
              <div className="personImg">
                <img src="/images/testimonials/person9.jpg" alt="" />
              </div>
              <div className="feedback-profile">
                <h3>Sanskriti Roy</h3>
                <p>Shaheed Rajguru College of Applied Sciences for Women</p>
              </div>
            </div>
          </div>
          <div className="feedback-card">
            <p className="feedback-message">
              Rinex Technologies Private Limited excels in innovative software
              solutions and IT services, delivering high-quality, client-focused
              products. Their expert team ensures customer satisfaction, making
              Rinex a reliable partner in the tech industry.
            </p>
            <div className="feedback-card-foot">
              <div className="personImg">
                <img src="/images/testimonials/person1.jpg" alt="" />
              </div>
              <div className="feedback-profile">
                <h3>Vignesh S</h3>
                <p>Sri Shakti College</p>
              </div>
            </div>
          </div>
          <div className="feedback-card">
            <p className="feedback-message">
              Rinex, It's very easy to learn and topics& all courses is
              available what people is trying to learn and budget friendly to
              learn the courses . Thank u Rinex
            </p>
            <div className="feedback-card-foot">
              <div className="personImg">
                <img src="/images/testimonials/person2.jpg" alt="" />
              </div>
              <div className="feedback-profile">
                <h3>Avala Gurumurthy</h3>
                <p>Vijayam Science and Arts Degree College </p>
              </div>
            </div>
          </div>
          <div className="feedback-card">
            <p className="feedback-message">
              It is a quality course. Keep going, we need this type of course to
              improve our studies. Excellent. Thank you!
            </p>
            <div className="feedback-card-foot">
              <div className="personImg">
                <img src="/images/testimonials/person3.jpg" alt="" />
              </div>
              <div className="feedback-profile">
                <h3>Prathiyumnan Palanisamy</h3>
                <p>Anna University</p>
              </div>
            </div>
          </div>
          <div className="feedback-card">
            <p className="feedback-message">
              I gained a lot from the WDC course, learning web development from
              excellent faculty who honed my skills and made me a professional
              developer. I can now create static web pages using HTML and CSS,
              select an appropriate web hosting service, and publish my webpages
              for the world to see.
            </p>
            <div className="feedback-card-foot">
              <div className="personImg">
                <img src="/images/testimonials/person4.jpg" alt="" />
              </div>
              <div className="feedback-profile">
                <h3>Jalla Venkata Sivananda</h3>
                <p>Sri Venkateshwara College of Engineering and Technology</p>
              </div>
            </div>
          </div>
          <div className="feedback-card">
            <p className="feedback-message">
              Choosing RINEX for my internship was a great decision, as I gained
              a lot of knowledge and learned many new things. The mentors were
              friendly and collaborative, making it a valuable opportunity for
              my future. Thank you, and I'm pleased to be part of this company.
            </p>
            <div className="feedback-card-foot">
              <div className="personImg">
                <img src="/images/testimonials/person5.jpg" alt="" />
              </div>
              <div className="feedback-profile">
                <h3>Nayanori Jahnavi</h3>
                <p>Sri Venkateshwara College of Engineering and Technology</p>
              </div>
            </div>
          </div>
          <div className="feedback-card">
            <p className="feedback-message">
              RINEX is a good option toy internship. I feel very good to join in
              this internship. I have learn more from this rinex, thank you.
            </p>
            <div className="feedback-card-foot">
              <div className="personImg">
                <img src="/images/testimonials/person6.jpg" alt="" />
              </div>
              <div className="feedback-profile">
                <h3>Rasineni Kavyanandini</h3>
                <p>Sri Venkateshwara College of Engineering and Technology</p>
              </div>
            </div>
          </div>
          <div className="feedback-card">
            <p className="feedback-message">
              Rinex is a robust and user-friendly platform for managing tasks,
              projects, and collaborations, with an intuitive interface and
              customizable workflows. Its powerful integrations with other tools
              enhance productivity and simplify complex workflows, making it
              highly regarded in various work environments.
            </p>
            <div className="feedback-card-foot">
              <div className="personImg">
                <img src="/images/testimonials/person7.jpg" alt="" />
              </div>
              <div className="feedback-profile">
                <h3>Alla Mokshith Naga Sai</h3>
                <p>Vignan University</p>
              </div>
            </div>
          </div>
          <div className="feedback-card">
            <p className="feedback-message">
              I recently completed the "Introduction to Data Science" course,
              which exceeded my expectations with its comprehensive content,
              practical examples, and hands-on projects. The knowledgeable
              instructors and well-paced structure significantly boosted my
              confidence in data science, making it an enriching experience I
              highly recommend to anyone starting in this field.
            </p>
            <div className="feedback-card-foot">
              <div className="personImg">
                <img src="/images/testimonials/person8.jpg" alt="" />
              </div>
              <div className="feedback-profile">
                <h3>Vinay Kumar</h3>
                <p>Alliance University</p>
              </div>
            </div>
          </div>
          <div className="feedback-card">
            <p className="feedback-message">
              The course curriculum is on par with leading industry standards,
              providing a wide range of courses that cater to various interests
              and skill levels. It's definitely worth giving a try for anyone
              looking to advance their knowledge and career.
            </p>
            <div className="feedback-card-foot">
              <div className="personImg">
                <img src="/images/testimonials/person9.jpg" alt="" />
              </div>
              <div className="feedback-profile">
                <h3>Sanskriti Roy</h3>
                <p>Shaheed Rajguru College of Applied Sciences for Women</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Feedback section ended -------- */}
      {/* <div className="google-review-link-container">
                <a href="https://www.google.com/search?q=rinex&rlz=1C1ONGR_enIN1004IN1004&oq=rinex+&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIKCAEQABixAxiABDINCAIQABiDARixAxiABDIHCAMQABiABDIGCAQQRRg8MgYIBRBFGDwyBggGEEUYPDIGCAcQRRg80gEIMTEyOWowajeoAgCwAgA&sourceid=chrome&ie=UTF-8" target="_blank" className="btn-google-review"><img src="/images/review_logo.svg" alt="" />Write your review</a>
            </div> */}
      <div id="communityNav"></div>
      {/* Community section started ----- */}
      <div className="community-section">
        <div className="community-card">
          <div className="community-card-head">
            <div>
              <p className="community-card-para">COMMUNITY</p>
              <h5 className="community-card-heading">Our Rinex Community</h5>
            </div>
            <div className="community-count-card-container">
              <div className="community-count-card">
                <p>AUTHORIZED UNIVERSITY</p>
                <h5>10+</h5>
              </div>
              <div className="community-count-card">
                <p>AUTHORIZED COMPANIES</p>
                <h5>30+</h5>
              </div>
              <div className="community-count-card">
                <p>INDUSTRY PROFFESIONAL</p>
                <h5>20+</h5>
              </div>
            </div>
          </div>

          {/* animated location logo  */}
          <img className="visa-location" src="/images/visa.svg" alt="" />
          <img
            className="deloitte-location"
            src="/images/deloitte.svg"
            alt=""
          />
          <img className="hp-location" src="/images/hp.svg" alt="" />
          <img
            className="accenture-location"
            src="/images/accenture.svg"
            alt=""
          />
          <img className="ola-location" src="/images/ola.svg" alt="" />
          <img className="jio-location" src="/images/jio.svg" alt="" />

          <p className="community-para-grey">
            Globally connected community over 30+ companies.
          </p>
        </div>
      </div>
      {/* Community section ended ----- */}

      <div id="contactNav"></div>

      {/* support-section started ----- */}
      <div className="support-section">
        <div className="support-card">
          <div>
            <p className="support-card-para">SUPPORT</p>
            <h5 className="support-card-heading">Rinex Acadamic Support</h5>
            <p className="support-card-para-grey">
              Get better help from our best support team of Rinex
            </p>
          </div>
          <div>
            <img className="circles" src="" alt="" />
            <img className="man" src="" alt="" />
            <img className="chat" src="" alt="" />
          </div>
        </div>
      </div>
      {/* support-section ended ----- */}

      {/* contacts card section started ------ */}
      <div className="contact-card-section">
        <a
          href="/#demoNav"
          style={{ textDecoration: "none" }}
          className="contact-card1"
        >
          <div>
            <p className="contact-card-para">DEMO</p>
            <h5 className="contact-card-heading">Request a free demo</h5>
            <div className="contact-card-img-1">
              <div>
                <img className="hand-logo" src="" alt="" />
                <img className="btn-request" src="" alt="" />
                <img
                  className="layer-1"
                  src="/images/request_layer_4.svg"
                  alt=""
                />
                <img
                  className="layer-2"
                  src="/images/request_layer_3.svg"
                  alt=""
                />
                <img
                  className="layer-3"
                  src="/images/request_layer_2.svg"
                  alt=""
                />
                <img
                  className="layer-4"
                  src="/images/request_layer_1.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <p className="request-para">
            Request <img src="/images/green_arrow.svg" alt="" />
          </p>
        </a>

        <a
          href="tel:+919429690310"
          style={{ textDecoration: "none" }}
          className="contact-card2"
        >
          <div>
            <p className="contact-card-para">CALL US</p>
            <h5 className="contact-card-heading">Have doubt? Call Us</h5>
            <div className="contact-card-img-2">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <img src="images/call_logo.svg" alt="" />
            </div>
          </div>
          <p className="contact-num-para">
            {" "}
            +91 94296 90310 <img src="/images/blue_arrow.svg" alt="" />
          </p>
        </a>

        <a
          href="mailto:help@rinex.ai"
          style={{ textDecoration: "none" }}
          className="contact-card3"
        >
          <div>
            <p className="contact-card-para">QUERY</p>
            <h5 className="contact-card-heading">Have query? Mail Us</h5>
            <div className="contact-card-img-3">
              <img className="blue-chat" src="" alt="" />
              <img className="white-chat" src="" alt="" />
            </div>
          </div>
          <p className="email-para">
            help@rinex.ai <img src="/images/darkblue_arrow.svg" alt="" />
          </p>
        </a>
      </div>
      {/* contacts card section ended ------ */}

      <div id="blogNav"></div>

      {/* blog section started --------- */}
      <div className="blog-section">
        <p className="blog-para">BLOGS</p>
        <h5 className="blog-heading">Insights & Stories</h5>
        <div className="blog-card-container">
          <Link to="/blog/1" className="blog-card1">
            <div className="blog-card1-head">
              <p className="blog-card-para">BLOGS</p>
              <h6 className="blog-card-title">Blog title</h6>
            </div>
            <p className="blog-card-para-grey">Rinex · 10 Mar, 2024</p>
          </Link>

          <Link to="/blog/2" className="blog-card1">
            <div className="blog-card2-head">
              <p className="blog-card-para">BLOGS</p>
              <h6 className="blog-card-title">Blog title</h6>
            </div>
            <p className="blog-card-para-grey">Rinex · 3 Feb, 2022</p>
          </Link>

          <Link to="/blog/3" className="blog-card1">
            <div className="blog-card3-head">
              <p className="blog-card-para">BLOGS</p>
              <h6 className="blog-card-title">Blog title</h6>
            </div>
            <p className="blog-card-para-grey">Rinex · 1 Jan, 2024</p>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}
