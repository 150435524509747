import React, { useEffect, useState } from "react";
import ProspleSearchWidget1 from "./ProspleSearchWidget1";

export function Career1(){

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => {
        setOffsetY(window.pageYOffset);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return(
        <React.Fragment>
            <div className="career-banner-section">
                <img style={{ transform: `translateY(${-offsetY * 0.6}px)` }}  src="/images/about-banner.png" alt="" />
                <p className="career-banner-para">CAREER</p>
                <h5 className="acareer-banner-heading">Find your relevant internships & jobs</h5>
                <div className="bc-wihite">
                <div className="making-better-para">
                <ProspleSearchWidget1 />
                </div>
                </div>
               
            </div>

            <div className="scrollable-culture-section">
                <div className="culture-card-scroll-container">
                    <img src="/images/culture_card1.png" alt="" />
                    <img src="/images/culture_card2.png" alt="" />
                    <img src="/images/culture_card3.png" alt="" />
                    <img src="/images/culture_card4.png" alt="" />
                    <img src="/images/culture_card1.png" alt="" />
                    <img src="/images/culture_card2.png" alt="" />
                    <img src="/images/culture_card3.png" alt="" />
                    <img src="/images/culture_card4.png" alt="" />
                    <img src="/images/culture_card1.png" alt="" />
                    <img src="/images/culture_card2.png" alt="" />
                    <img src="/images/culture_card3.png" alt="" />
                    <img src="/images/culture_card4.png" alt="" />
                    <img src="/images/culture_card1.png" alt="" />
                    <img src="/images/culture_card2.png" alt="" />
                    <img src="/images/culture_card3.png" alt="" />
                    <img src="/images/culture_card4.png" alt="" />
                </div>
            </div>


            <div className="making-better-section">
                <div className="making-better-container">
                    <p className="making-better-para">MAKING BETTER</p>
                    <div className="making-better-head">
                        <h5 className="making-better-heading">We are on a Mission</h5>
                        <p className="making-better-para-grey">We're on a mission to spread around and help every possible individual who needs us. Our vision is to diversify education standards and make the youth Job Ready. We know there would come challenges but we love them all, they help us improve and grow even more.</p>
                    </div>


                    <div className="making-better-foot">
                       
                    </div>
                </div>
            </div>

            <div className="career-card-section">
                <div className="career-card1">
                    <img data-aos="fade-right" data-aos-duration="1000" src="" alt="" />
                    <img data-aos="fade-left" data-aos-duration="1000" src="" alt="" />
                    <p className="career-card-para">EDUCATION</p>
                    <h5 className="career-card-heading">Giving better Education</h5>
                    <p className="career-card-para-grey">We as an organization believe that education is the most important factor playing in an individual's success.These are the days of innovation and change, you need to update yourself if you want to be in the industry, and that can only be done with Education.</p>
                </div>

                <div className="career-card2">
                    <p className="career-card-para">BRIDGE</p>
                    <h5 className="career-card-heading">Bridging the Gap</h5>
                    <p className="career-card-para-grey">There is a vast gap between what is been taught in the Colleges and what the industry demands. We owe to serve this gap and make students job ready having the skills expected by the potential employers.</p>
                    <div className="bridge-card-animation-container">
                        <img src="/images/bridge.png" alt="" />
                        <img src="/images/bridge_pillar.png" alt="" />
                    </div>
                </div>

                <div className="career-card3">
                    <p className="career-card-para">CAREER</p>
                    <h5 className="career-card-heading">Better Placements</h5>
                    <p className="career-card-para-grey">Rinex has always been very focused on Placements of students at their desired companies and institutions. Because, your skills land you a dream job not just dreams. And for that we're here to build your skills at the same time council you for the Placements. So that you go to a place you deserve.</p>
                    <div className="partner-card-animation-container">
                        <div>
                            <img src="/images/dubai.svg" alt="" />
                            <img src="/images/morgan.svg" alt="" />
                            <img src="/images/dubai.svg" alt="" />
                            <img src="/images/morgan.svg" alt="" />
                            <img src="/images/dubai.svg" alt="" />
                            <img src="/images/morgan.svg" alt="" />
                            <img src="/images/dubai.svg" alt="" />
                            <img src="/images/morgan.svg" alt="" />
                            <img src="/images/dubai.svg" alt="" />
                            <img src="/images/morgan.svg" alt="" />
                            <img src="/images/dubai.svg" alt="" />
                            <img src="/images/morgan.svg" alt="" />
                            <img src="/images/dubai.svg" alt="" />
                            <img src="/images/morgan.svg" alt="" />
                            <img src="/images/dubai.svg" alt="" />
                            <img src="/images/morgan.svg" alt="" />
                            <img src="/images/dubai.svg" alt="" />
                            <img src="/images/morgan.svg" alt="" />
                            <img src="/images/dubai.svg" alt="" />
                        </div>
                        <div>
                            <img src="/images/microsoft.svg" alt="" />
                            <img src="/images/mistral.svg" alt="" />
                            <img src="/images/microsoft.svg" alt="" />
                            <img src="/images/mistral.svg" alt="" />
                            <img src="/images/microsoft.svg" alt="" />
                            <img src="/images/mistral.svg" alt="" />
                            <img src="/images/microsoft.svg" alt="" />
                            <img src="/images/mistral.svg" alt="" />
                            <img src="/images/microsoft.svg" alt="" />
                            <img src="/images/mistral.svg" alt="" />
                            <img src="/images/microsoft.svg" alt="" />
                            <img src="/images/mistral.svg" alt="" />
                            <img src="/images/microsoft.svg" alt="" />
                            <img src="/images/microsoft.svg" alt="" />
                            <img src="/images/mistral.svg" alt="" />
                            <img src="/images/microsoft.svg" alt="" />
                            <img src="/images/mistral.svg" alt="" />
                        </div>
                        <div>
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/state.svg" alt="" />
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/state.svg" alt="" />
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/state.svg" alt="" />
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/state.svg" alt="" />
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/state.svg" alt="" />
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/state.svg" alt="" />
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/state.svg" alt="" />
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/state.svg" alt="" />
                            <img src="/images/bosch.svg" alt="" />
                            <img src="/images/state.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div id="jobOffers">

            </div>
            <div className="job-role-card-section">
            </div>
        </React.Fragment>
    )
} 