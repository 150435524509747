import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import blogData from "../data/blog.json";

export function Blog(){
    const[blogDetails, setBlogDetails] = useState({});
    const params = useParams();

    function getBlogData(){
        const selectedBlog = blogData.find(item => item.id === params.blogId);

        if (selectedBlog) {
            setBlogDetails(selectedBlog);
        }
    }

    useEffect(()=>{
        getBlogData();
    },[params])

    
    useEffect(()=>{
        window.scrollTo(0,0)
    },[blogDetails])

    return(
        <React.Fragment>
            <div className="blog-details-section">
                <div className="blog-details-left-side">
                    <img src="/images/blog_large_img.png"></img>
                    <p className="blog_chip-para">BLOG {blogDetails.id}</p>

                    {/* blog-head section started --- */}
                    <div className="blog-details-head-section">
                        <h4 className="blog-title-heading">{blogDetails.blogTItle}</h4>
                        <div>
                            <p className="blog-date-para">{blogDetails.blogDate}</p>
                            {/* Share buttun ----- */}
                            <button className="btn-share-blog">
                                <img src="/images/share_img.svg" alt="" />
                                Share 
                                <img src="/images/tilt_arrow.svg" alt="" />
                            </button>
                        </div>
                    </div>
                    {/* blog-head section ended --- */}

                    <img src="/images/horizontal_divider.svg" alt="" />

                    {/* blog-content section started --- */}
                    <div>
                        <p className="content-para-black">READ FROM HERE</p>
                        <p className="content-para-grey">{blogDetails.blogPara1}</p>
                        <p className="content-para-grey">{blogDetails.blogPara2}</p>
                        <p className="content-para-grey">{blogDetails.blogPara3}</p>

                        <p className="content-para-black">What are the points being covered</p>
                        <p className="content-para-grey">{blogDetails.blogPara4}</p>
                        <p className="content-para-grey">{blogDetails.blogPara5}</p>
                        <p className="content-para-grey">{blogDetails.blogPara6}</p>

                        <p className="content-para-black">What are the points being covered</p>
                        <p className="content-para-grey">{blogDetails.blogPara7}</p>
                        <p className="content-para-grey">{blogDetails.blogPara8}</p>
                        <p className="content-para-grey">{blogDetails.blogPara9}</p>
                    </div>
                    {/* blog-content section ended --- */}
                </div>

                <div className="blog-details-right-side">
                    <div className="blog-register-card" >
                        <a href="/#onlineCourse"  style={{ textDecoration: "none"}}>
                            <p className="blog-register-card-para">REGISTER</p>
                            <h5 className="blog-register-card-heading">Awesome discounts on all courses</h5>
                            <p className="blog-register-card-offer-para">₹500 OFF on every courses, Get start for owning a new skill.</p>
                            <div className="offer-card-tag-1">
                                <img src="/images/yellow_tag.svg" alt="" />
                                <img src="/images/purple_tag.svg" alt="" />
                                <img src="/images/offer_tag.svg" alt="" />
                            </div>
                            <p className="blog-register-card-btn">Register <img alt="" /></p>
                        </a>
                    </div>
                </div> 
            </div>

            {/* blog section started --------- */}
            <div className="blog-section">
                <p className="blog-para">
                    BLOGS
                </p>
                <h5 className="blog-heading" >Insights & Stories</h5>
                <div className="blog-card-container">
                    <Link to="/blog/1" className="blog-card1">
                        <div className="blog-card1-head" >
                            <p className="blog-card-para">BLOGS</p>
                            <h6 className="blog-card-title">Blog title</h6>
                        </div>
                        <p className="blog-card-para-grey">Rinex · 10 Mar, 2024</p>
                    </Link>

                    <Link to="/blog/2" className="blog-card1">
                        <div className="blog-card2-head" >
                            <p className="blog-card-para">BLOGS</p>
                            <h6 className="blog-card-title">Blog title</h6>
                        </div>
                        <p className="blog-card-para-grey">Rinex · 3 Feb, 2022</p>
                    </Link>

                    <Link to="/blog/3" className="blog-card1">
                        <div className="blog-card3-head" >
                            <p className="blog-card-para">BLOGS</p>
                            <h6 className="blog-card-title">Blog title</h6>
                        </div>
                        <p className="blog-card-para-grey">Rinex · 1 Jan, 2024</p>
                    </Link>
                </div>
            </div>
             {/* blog section ended --------- */}
        </React.Fragment>
    )
}