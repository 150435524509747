import React, { useEffect, useState } from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { Home } from "./home";
import { Programs } from "./programs";
import { Career } from "./career";
import { AboutUs } from "./about";
import { Blog } from "./blog";
import { JobRole } from "./jobrole";
import { FreeProgram } from "./freeprogram";
import LocomotiveScroll from "locomotive-scroll";
const locomotiveScroll = new LocomotiveScroll();

export function Index() {
  const [theme, setTheme] = useState("light-theme");
  const [activeBtnEffect, setActiveBtnEffect] = useState({
    border: "1.5px solid rgba(212, 212, 212, 1)",
    backgroundColor: "rgba(246, 246, 246, 1)",
    boxShadow: "inset 0px -5px 1px rgba(233, 233, 233, 1)",
    color: "rgba(0, 0, 0, 1)",
  });
  const [subCategories, setSubCategories] = useState([
    { id: 0, programName: "", logo: "" },
  ]);
  const [progCat, setProgCat] = useState("");
  const [themImg, setThemeImage] = useState({
    sun: "/images/sun_light.svg",
    moon: "/images/moon.svg",
  });

  useEffect(() => {
    document.getElementById("themeContainer").className = theme;
  }, [theme]);

  useEffect(() => {
    document.getElementById("lightDiv").style.backgroundColor =
      "rgba(129, 129, 129, 1)";
  }, []);

  function handleThemeChange() {
    if (theme === "light-theme") {
      setTheme("dark-theme");
      document.getElementById("darkDiv").style.backgroundColor =
        "rgba(129, 129, 129, 1)";
      document.getElementById("lightDiv").style.backgroundColor = "transparent";
      document.getElementById("hero-video").src =
        "/images/herosectionbackgroundDark.mp4";
      setThemeImage({ sun: "/images/sun.svg", moon: "/images/moon_light.svg" });
      setActiveBtnEffect({
        border: "1.5px solid rgba(45, 45, 45, 1)",
        backgroundColor: "rgba(32, 32, 32, 1)",
        boxShadow: "inset 0px -5px 1px rgba(43, 43, 43, 1)",
        color: "rgba(240, 240, 240, 1)",
      });
    } else {
      setTheme("light-theme");
      setThemeImage({ sun: "/images/sun_light.svg", moon: "/images/moon.svg" });
      document.getElementById("lightDiv").style.backgroundColor =
        "rgba(129, 129, 129, 1)";
      document.getElementById("darkDiv").style.backgroundColor = "transparent";
      document.getElementById("hero-video").src =
        "/images/herosectionbackground.mp4";
      setActiveBtnEffect({
        border: "1.5px solid rgba(212, 212, 212, 1)",
        backgroundColor: "rgba(246, 246, 246, 1)",
        boxShadow: "inset 0px -5px 1px rgba(233, 233, 233, 1)",
        color: "rgba(0, 0, 0, 1)",
      });
    }
  }

  // document.addEventListener("DOMContentLoaded", () => {
  //   const applyTheme = (theme) => {
  //     document.body.classList.remove("light-theme", "dark-theme");
  //     document.body.classList.add(theme + "-theme");
  //   };
  //   const prefersDarkScheme = window.matchMedia(
  //     "(prefers-color-scheme: dark)"
  //   ).matches;
  //   applyTheme(prefersDarkScheme ? "dark" : "light");
  //   window
  //     .matchMedia("(prefers-color-scheme: dark)")
  //     .addEventListener("change", (e) => {
  //       const newColorScheme = e.matches ? "dark" : "light";
  //       applyTheme(newColorScheme);
  //     });
  // });
  // header navbutton active effect function ----
  function headerActiveEffect() {
    if (window.location.pathname === "/") {
      document.getElementById("headerHome").style.border =
        activeBtnEffect.border;
      document.getElementById("headerHome").style.backgroundColor =
        activeBtnEffect.backgroundColor;
      document.getElementById("headerHome").style.boxShadow =
        activeBtnEffect.boxShadow;
      document.getElementById("headerHome").style.color = activeBtnEffect.color;
    } else if (window.location.pathname === "/about") {
      document.getElementById("headerAbout").style.border =
        activeBtnEffect.border;
      document.getElementById("headerAbout").style.backgroundColor =
        activeBtnEffect.backgroundColor;
      document.getElementById("headerAbout").style.boxShadow =
        activeBtnEffect.boxShadow;
      document.getElementById("headerAbout").style.color =
        activeBtnEffect.color;
    } else if (window.location.pathname === "/career") {
      document.getElementById("headerCareer").style.border =
        activeBtnEffect.border;
      document.getElementById("headerCareer").style.backgroundColor =
        activeBtnEffect.backgroundColor;
      document.getElementById("headerCareer").style.boxShadow =
        activeBtnEffect.boxShadow;
      document.getElementById("headerCareer").style.color =
        activeBtnEffect.color;
    }
  }
  useEffect(() => {
    headerActiveEffect();
  }, [theme]);
  // /////////////

  function handleHeaderHomeEffect() {
    document.getElementById("headerHome").style.border = activeBtnEffect.border;
    document.getElementById("headerHome").style.backgroundColor =
      activeBtnEffect.backgroundColor;
    document.getElementById("headerHome").style.boxShadow =
      activeBtnEffect.boxShadow;
    document.getElementById("headerHome").style.color = activeBtnEffect.color;

    document.getElementById("headerAbout").style.border = "none";
    document.getElementById("headerAbout").style.backgroundColor =
      "transparent";
    document.getElementById("headerAbout").style.boxShadow = "none";
    document.getElementById("headerAbout").style.color =
      "rgba(135, 135, 135, 1)";

    document.getElementById("headerCareer").style.border = "none";
    document.getElementById("headerCareer").style.backgroundColor =
      "transparent";
    document.getElementById("headerCareer").style.boxShadow = "none";
    document.getElementById("headerCareer").style.color =
      "rgba(135, 135, 135, 1)";

    document.getElementById("headerBlogs").style.border = "none";
    document.getElementById("headerBlogs").style.backgroundColor =
      "transparent";
    document.getElementById("headerBlogs").style.boxShadow = "none";
    document.getElementById("headerBlogs").style.color =
      "rgba(135, 135, 135, 1)";

    document.getElementById("btnHeadProgram").style.border = "none";
    document.getElementById("btnHeadProgram").style.backgroundColor =
      "transparent";
    document.getElementById("btnHeadProgram").style.boxShadow = "none";
    document.getElementById("btnHeadProgram").style.color =
      "rgba(135, 135, 135, 1)";
  }

  function handleHeaderAboutEffect() {
    document.getElementById("headerHome").style.border = "none";
    document.getElementById("headerHome").style.backgroundColor = "transparent";
    document.getElementById("headerHome").style.boxShadow = "none";
    document.getElementById("headerHome").style.color =
      "rgba(135, 135, 135, 1)";

    document.getElementById("btnHeadProgram").style.border = "none";
    document.getElementById("btnHeadProgram").style.backgroundColor =
      "transparent";
    document.getElementById("btnHeadProgram").style.boxShadow = "none";
    document.getElementById("btnHeadProgram").style.color =
      "rgba(135, 135, 135, 1)";

    document.getElementById("headerCareer").style.border = "none";
    document.getElementById("headerCareer").style.backgroundColor =
      "transparent";
    document.getElementById("headerCareer").style.boxShadow = "none";
    document.getElementById("headerCareer").style.color =
      "rgba(135, 135, 135, 1)";

    document.getElementById("headerBlogs").style.border = "none";
    document.getElementById("headerBlogs").style.backgroundColor =
      "transparent";
    document.getElementById("headerBlogs").style.boxShadow = "none";
    document.getElementById("headerBlogs").style.color =
      "rgba(135, 135, 135, 1)";

    document.getElementById("headerAbout").style.border =
      activeBtnEffect.border;
    document.getElementById("headerAbout").style.backgroundColor =
      activeBtnEffect.backgroundColor;
    document.getElementById("headerAbout").style.boxShadow =
      activeBtnEffect.boxShadow;
    document.getElementById("headerAbout").style.color = activeBtnEffect.color;
  }

  function handleHeaderCareerEffect() {
    document.getElementById("headerHome").style.border = "none";
    document.getElementById("headerHome").style.backgroundColor = "transparent";
    document.getElementById("headerHome").style.boxShadow = "none";
    document.getElementById("headerHome").style.color =
      "rgba(135, 135, 135, 1)";

    document.getElementById("headerAbout").style.border = "none";
    document.getElementById("headerAbout").style.backgroundColor =
      "transparent";
    document.getElementById("headerAbout").style.boxShadow = "none";
    document.getElementById("headerAbout").style.color =
      "rgba(135, 135, 135, 1)";

    document.getElementById("btnHeadProgram").style.border = "none";
    document.getElementById("btnHeadProgram").style.backgroundColor =
      "transparent";
    document.getElementById("btnHeadProgram").style.boxShadow = "none";
    document.getElementById("btnHeadProgram").style.color =
      "rgba(135, 135, 135, 1)";

    document.getElementById("headerBlogs").style.border = "none";
    document.getElementById("headerBlogs").style.backgroundColor =
      "transparent";
    document.getElementById("headerBlogs").style.boxShadow = "none";
    document.getElementById("headerBlogs").style.color =
      "rgba(135, 135, 135, 1)";

    document.getElementById("headerCareer").style.border =
      activeBtnEffect.border;
    document.getElementById("headerCareer").style.backgroundColor =
      activeBtnEffect.backgroundColor;
    document.getElementById("headerCareer").style.boxShadow =
      activeBtnEffect.boxShadow;
    document.getElementById("headerCareer").style.color = activeBtnEffect.color;
  }

  function handleHeaderBlogEffect() {
    document.getElementById("headerHome").style.border = "none";
    document.getElementById("headerHome").style.backgroundColor = "transparent";
    document.getElementById("headerHome").style.boxShadow = "none";
    document.getElementById("headerHome").style.color =
      "rgba(135, 135, 135, 1)";

    document.getElementById("headerAbout").style.border = "none";
    document.getElementById("headerAbout").style.backgroundColor =
      "transparent";
    document.getElementById("headerAbout").style.boxShadow = "none";
    document.getElementById("headerAbout").style.color =
      "rgba(135, 135, 135, 1)";

    document.getElementById("btnHeadProgram").style.border = "none";
    document.getElementById("btnHeadProgram").style.backgroundColor =
      "transparent";
    document.getElementById("btnHeadProgram").style.boxShadow = "none";
    document.getElementById("btnHeadProgram").style.color =
      "rgba(135, 135, 135, 1)";

    document.getElementById("headerCareer").style.border = "none";
    document.getElementById("headerCareer").style.backgroundColor =
      "transparent";
    document.getElementById("headerCareer").style.boxShadow = "none";
    document.getElementById("headerCareer").style.color =
      "rgba(135, 135, 135, 1)";

    document.getElementById("headerBlogs").style.border =
      activeBtnEffect.border;
    document.getElementById("headerBlogs").style.backgroundColor =
      activeBtnEffect.backgroundColor;
    document.getElementById("headerBlogs").style.boxShadow =
      activeBtnEffect.boxShadow;
    document.getElementById("headerBlogs").style.color = activeBtnEffect.color;
  }

  function addBlogScrollSmooth() {
    document.querySelector("html").style.scrollBehavior = "smooth";
  }

  function removeBlogScrollSmooth() {
    document.querySelector("html").style.scrollBehavior = "unset";
  }

  // Elite related

  function openEliteMenu() {
    clearTimeout(menuTimeout);
    document.getElementById("elite-menu-container").style.display = "block";
  }

  function closeEliteMenu() {
    menuTimeout = setTimeout(() => {
      document.getElementById("elite-menu-container").style.display = "none";
    }, 200);
  }

  function openEliteModal() {
    document.getElementById("elite-modal-section").style.display = "block";
  }

  function closeEliteModal() {
    document.getElementById("elite-modal-section").style.display = "none";
  }

  // program menu handling -------
  function openProgramMenu() {
    document.getElementById("program-menu-container").style.display = "flex";
  }
  function closeProgramMenu() {
    document.getElementById("program-menu-container").style.display = "none";
  }

  let menuTimeout;

  function openCsLinks() {
    document.getElementById("cs-program-link").style.display = "flex";
  }

  function closeCsLinks() {
    document.getElementById("cs-program-link").style.display = "none";
  }

  function openCivilLinks() {
    document.getElementById("civil-program-link").style.display = "flex";
  }

  function closeCivilLinks() {
    document.getElementById("civil-program-link").style.display = "none";
  }

  function openExtcLinks() {
    document.getElementById("extc-program-link").style.display = "flex";
  }

  function closeExtcLinks() {
    document.getElementById("extc-program-link").style.display = "none";
  }

  function openMechLinks() {
    document.getElementById("mech-program-link").style.display = "flex";
  }

  function closeMechLinks() {
    document.getElementById("mech-program-link").style.display = "none";
  }

  // program menu handling -------

  function openMainMenu() {
    document.getElementById("headerMainMenu").style.marginTop = "0px";
    document.getElementById("headerMobile").style.display = "none";
    document.getElementById("program-categories-modal-section").style.display =
      "none";
    document.getElementById("program-categories-modal").style.display = "flex";
    document.getElementById("program-subcategories-modal").style.display =
      "none";
  }

  function closeMainMenu() {
    document.getElementById("headerMainMenu").style.marginTop = "-300vh";
    document.getElementById("headerMobile").style.display = "block";
  }

  function openProgramCategories() {
    document.getElementById("program-categories-modal-section").style.display =
      "block";
  }
  function closeProgramCategories() {
    document.getElementById("program-categories-modal-section").style.display =
      "none";
  }

  function openCsProgramModal(e) {
    setSubCategories([
      {
        id: 11,
        programName: "Data Structures and Algorithms in JAVA",
        logo: "/images/ai_logo.svg",
      },
      {
        id: 1,
        programName: "Artificial Intelligence",
        logo: "/images/ai_logo.svg",
      },
      {
        id: 2,
        programName: "Cyber Security & Ethical Hacking",
        logo: "/images/security_logo.svg",
      },
      {
        id: 3,
        programName: "Data Science",
        logo: "/images/data_logo.svg",
      },
      {
        id: 4,
        programName: "Machine Learning with Python",
        logo: "/images/gear_logo.svg",
      },
      {
        id: 5,
        programName: "Web Development",
        logo: "/images/web_logo.svg",
      },
    ]);
    setProgCat("Computer Science / Information Science");
    document.getElementById("program-categories-modal").style.display = "none";
    document.getElementById("program-subcategories-modal").style.display =
      "flex";
  }
  function openCivilProgramModal(e) {
    setSubCategories([
      {
        id: 6,
        programName: "AutoCad",
        logo: "/images/autocad_logo.svg",
      },
    ]);
    setProgCat("civil engineering");
    document.getElementById("program-categories-modal").style.display = "none";
    document.getElementById("program-subcategories-modal").style.display =
      "flex";
  }
  function openExtcProgramModal(e) {
    setSubCategories([
      {
        id: 7,
        programName: "Internet of Things",
        logo: "/images/cloud_upload.svg",
      },
    ]);
    setProgCat("Electronics & Communication Engineering");
    document.getElementById("program-categories-modal").style.display = "none";
    document.getElementById("program-subcategories-modal").style.display =
      "flex";
  }
  function openMechProgramModal(e) {
    setSubCategories([
      {
        id: 8,
        programName: "AutoCAD & MATLAB",
        logo: "/images/autocad_logo2.svg",
      },
    ]);
    setProgCat("Mechanical Engineering");

    document.getElementById("program-categories-modal").style.display = "none";
    document.getElementById("program-subcategories-modal").style.display =
      "flex";
  }

  function closeProgramSubCategoriesModal() {
    document.getElementById("program-categories-modal").style.display = "flex";
    document.getElementById("program-subcategories-modal").style.display =
      "none";
  }

  return (
    <React.Fragment>
      <div id="themeContainer">
        <div className="background-video-container">
          <video
            width=""
            height=""
            autoPlay
            muted
            loop
            className="hero-video"
            id="hero-video"
            src="/images/herosectionbackground.mp4"
          ></video>
        </div>
        <BrowserRouter>
          <header className="header-desktop">
            <div className="header-left-side">
              <Link onClick={handleHeaderHomeEffect} to="/">
                <img loading="lazy" className="rinex-header-logo" alt="" />
              </Link>

              <Link
                id="headerHome"
                className="nav-link"
                onMouseEnter={openEliteMenu}
                onMouseLeave={closeEliteMenu}
              >
                Elite <img src="/images/down_arrow.svg" alt="" />
                <div
                  id="elite-menu-container"
                  className="menu-container"
                  onMouseEnter={openEliteMenu} // Keep the menu open when hovering over the menu
                  onMouseLeave={closeEliteMenu} // Close the menu when the mouse leaves the menu
                >
                  <div className="elite-link-menu">
                    <div>
                      <img src="/images/programm_icon.svg" alt="" />
                      <div>
                        <p className="elite-menu-para"></p>
                        <h6 className="elite-menu-heading">Elite Programs</h6>
                      </div>
                    </div>

                    <Link
                      to="/ep/DSAPython.html"
                      className="nav-sub-link-elite"
                      onClick={() =>
                        (window.location.href = "/ep/DSAPython.html")
                      }
                    >
                      <img src="/images/pythonElite.png" alt="" />
                      <div>
                        <h6 className="elite-submenu-option">
                          Data Structures and Algorithms with Python
                        </h6>
                      </div>
                    </Link>
                    <Link
                      to="/ep/DSAPython.html"
                      className="nav-sub-link-elite"
                      onClick={() =>
                        (window.location.href = "/ep/DSAJava.html")
                      }
                    >
                      <img src="/images/javaElite.png" alt="" />
                      <div>
                        <h6 className="elite-submenu-option">
                          Data Structures and Algorithms with Java
                        </h6>
                      </div>
                    </Link>

                    <Link
                      to="/ep/DSAPython.html"
                      className="nav-sub-link-elite"
                      onClick={() => (window.location.href = "/ep/DSAC++.html")}
                    >
                      <img src="/images/c++Elite.png" alt="" />
                      <div>
                        <h6 className="elite-submenu-option">
                          Data Structures and Algorithms with C++
                        </h6>
                      </div>
                    </Link>

                    <Link
                      to="/ep/DSAPython.html"
                      className="nav-sub-link-elite"
                      onClick={() => (window.location.href = "/ep/uiux.html")}
                    >
                      <img src="/images/uiuxElite.png" alt="" />
                      <div>
                        <h6 className="elite-submenu-option">UI/UX</h6>
                      </div>
                    </Link>

                    <Link
                      to="/ep/DSAPython.html"
                      className="nav-sub-link-elite"
                      onClick={() =>
                        (window.location.href = "/ep/digitalmarketing.html")
                      }
                    >
                      <img src="/images/digitalmarketingElite.png" alt="" />
                      <div>
                        <h6 className="elite-submenu-option">
                          Digital Marketing
                        </h6>
                      </div>
                    </Link>

                    {/* Add additional submenu items here */}
                  </div>
                </div>
              </Link>

              <Link
                onClick={handleHeaderAboutEffect}
                to="/about"
                className="nav-link"
                id="headerAbout"
              >
                About
              </Link>

              <Link
                onMouseOver={openProgramMenu}
                onMouseOut={closeProgramMenu}
                id="btnHeadProgram"
                className="nav-link"
              >
                Programs <img src="/images/down_arrow.svg" alt="" />
                <div id="program-menu-container" className="menu-container">
                  <div className="program-link-menu">
                    <div>
                      <img src="/images/programm_icon.svg" alt="" />
                      <div>
                        <p className="program-menu-para">PROGRAMS</p>
                        <h6 className="program-menu-heading">
                          See all programs
                        </h6>
                      </div>
                    </div>

                    <div onMouseOut={closeCsLinks} onMouseOver={openCsLinks}>
                      <img src="/images/cs_logo.svg" alt="" />
                      <div>
                        <h6 className="program-menu-option">
                          Computer Science/Information Science
                        </h6>
                      </div>

                      <div
                        id="cs-program-link"
                        className="program-sublink-container-cs"
                      >
                        <div className="program-sublink-menu">
                          <div>
                            <p className="sublink-menu-para">
                              COMPUTER SCIENCE / INFORMATION SCIENCE
                            </p>
                          </div>

                          {/* <Link
                            onClick={closeProgramMenu}
                            to="/program/11"
                            className="nav-sub-link"
                          >
                            <img src="/images/autocad_logo.svg" alt="" />
                            <div>
                              <h6 className="program-submenu-option">
                                Data Structures and Algorithms
                              </h6>
                            </div>
                          </Link> */}

                          <Link
                            onClick={closeProgramMenu}
                            to="/program/1"
                            className="nav-sub-link"
                          >
                            <img src="/images/ai_logo.svg" alt="" />
                            <div>
                              <h6 className="program-submenu-option">
                                Artificial Intelligence
                              </h6>
                            </div>
                          </Link>

                          <Link
                            onClick={closeProgramMenu}
                            to="/program/2"
                            className="nav-sub-link"
                          >
                            <img src="/images/security_logo.svg" alt="" />
                            <div>
                              <h6 className="program-submenu-option">
                                Cyber Security & Ethical Hacking
                              </h6>
                            </div>
                          </Link>

                          <Link
                            onClick={closeProgramMenu}
                            to="/program/3"
                            className="nav-sub-link"
                          >
                            <img src="/images/data_logo.svg" alt="" />
                            <div>
                              <h6 className="program-submenu-option">
                                Data Science
                              </h6>
                            </div>
                          </Link>

                          <Link
                            onClick={closeProgramMenu}
                            to="/program/4"
                            className="nav-sub-link"
                          >
                            <img src="/images/gear_logo.svg" alt="" />
                            <div>
                              <h6 className="program-submenu-option">
                                Machine Learning with Python
                              </h6>
                            </div>
                          </Link>

                          <Link
                            onClick={closeProgramMenu}
                            to="/program/5"
                            className="nav-sub-link"
                          >
                            <img src="/images/web_logo.svg" alt="" />
                            <div>
                              <h6 className="program-submenu-option">
                                Web Development
                              </h6>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div
                      onMouseOut={closeCivilLinks}
                      onMouseOver={openCivilLinks}
                    >
                      <img src="/images/civil_logo.svg" alt="" />
                      <div>
                        <h6 className="program-menu-option">
                          Civil Engineering
                        </h6>
                      </div>

                      <div
                        id="civil-program-link"
                        className="program-sublink-container-civil"
                      >
                        <div className="program-sublink-menu">
                          <div>
                            <p className="sublink-menu-para">
                              CIVIL ENGINEERING
                            </p>
                          </div>

                          <Link
                            onClick={closeProgramMenu}
                            to="/program/6"
                            className="nav-sub-link"
                          >
                            <img src="/images/autocad_logo.svg" alt="" />
                            <div>
                              <h6 className="program-submenu-option">
                                AutoCAD
                              </h6>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div
                      onMouseOut={closeExtcLinks}
                      onMouseOver={openExtcLinks}
                    >
                      <img src="/images/extc_logo.svg" alt="" />
                      <div>
                        <h6 className="program-menu-option">
                          Electronics & Communication Engineering
                        </h6>
                      </div>

                      <div
                        id="extc-program-link"
                        className="program-sublink-container-extc"
                      >
                        <div className="program-sublink-menu">
                          <div>
                            <p className="sublink-menu-para">
                              Electronics & Communication Engineering
                            </p>
                          </div>

                          <Link
                            onClick={closeProgramMenu}
                            to="/program/7"
                            className="nav-sub-link"
                          >
                            <img src="/images/cloud_upload.svg" alt="" />
                            <div>
                              <h6 className="program-submenu-option">
                                Internet of Things
                              </h6>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div
                      onMouseOut={closeMechLinks}
                      onMouseOver={openMechLinks}
                    >
                      <img src="/images/mech_logo.svg" alt="" />
                      <div>
                        <h6 className="program-menu-option">
                          Mechanical Engineering
                        </h6>
                      </div>

                      <div
                        id="mech-program-link"
                        className="program-sublink-container-mech"
                      >
                        <div className="program-sublink-menu">
                          <div>
                            <p className="sublink-menu-para">
                              Mechanical Engineering
                            </p>
                          </div>

                          <Link
                            onClick={closeProgramMenu}
                            to="/program/8"
                            className="nav-sub-link"
                          >
                            <img src="/images/autocad_logo2.svg" alt="" />
                            <div>
                              <h6 className="program-submenu-option">
                                AutoCAD & MATLAB
                              </h6>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link
                onClick={handleHeaderCareerEffect}
                to="/career"
                id="headerCareer"
                className="nav-link"
              >
                Career <span className="hiring-chip">WE ARE HIRING</span>
              </Link>

              <a
                onClick={handleHeaderBlogEffect}
                onMouseOver={addBlogScrollSmooth}
                onMouseOut={removeBlogScrollSmooth}
                href="/#blogNav"
                id="headerBlogs"
                className="nav-link"
              >
                Blogs
              </a>
            </div>

            <div className="header-right-side">
              <button id="btnToggle" onClick={handleThemeChange}>
                <div id="lightDiv">
                  <img src={themImg.sun} alt="" />
                </div>
                <img id="sunImg" src="/images/vertical.svg" alt="" />
                <div id="darkDiv">
                  <img src={themImg.moon} alt="" />
                </div>
              </button>

              <button
                onClick={() => {
                  window.open(
                    "https://rinex-s-school.thinkific.com/users/sign_up",
                    "_blank"
                  );
                }}
                className="btn-signUp"
              >
                <img className="person-icon" alt="" />
                Sign Up
              </button>

              <a
                href="https://rinex-s-school.thinkific.com/users/sign_in"
                target="_blank"
                className="btn-logIn"
              >
                <img src="/images/login_icon.svg" alt="" /> Login
              </a>
            </div>
          </header>
          {/* desktop header ended --------- */}

          {/* mobile header started ----- */}
          <header className="header-mobile" id="headerMobile">
            <div className="header-container-mobile" id="headerContainerMobile">
              <Link to="/">
                <img className="rinex-header-logo" alt="" />
              </Link>
              <button onClick={openMainMenu} className="btn-header-menu">
                <img src="" alt="" />
              </button>
            </div>
          </header>

          <div id="headerMainMenu">
            <div>
              <div>
                <div id="headerContainerMobile" style={{ padding: "35px" }}>
                  <Link onClick={closeMainMenu} to="/">
                    <img className="rinex-header-logo" alt="" />
                  </Link>

                  <button
                    onClick={closeMainMenu}
                    className="btn-header-menu-close"
                  >
                    <img src="" alt="" />
                  </button>
                </div>

                <div className="menu-category-contianer">
                  <ul>
                    <li>
                      <a
                        href="#"
                        onClick={openEliteModal}
                        className="nav-link elite-mobile-header"
                      >
                        Elite
                      </a>
                    </li>
                    <li>
                      <Link
                        onClick={closeMainMenu}
                        to="/about"
                        className="menuLink"
                      >
                        About
                      </Link>
                    </li>
                    <li onClick={openProgramCategories}>
                      Program <img src="/images/right_arrow_grey.svg" alt="" />
                    </li>
                    <li>
                      <Link
                        onClick={closeMainMenu}
                        to="/career"
                        className="menuLink"
                      >
                        Career{" "}
                        <span className="hiring-chip">WE ARE HIRING</span>
                      </Link>
                    </li>
                    <li>
                      <a
                        onMouseOver={addBlogScrollSmooth}
                        onMouseOut={removeBlogScrollSmooth}
                        onClick={closeMainMenu}
                        href="/#blogNav"
                        className="menuLink"
                      >
                        blog
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <button id="btnToggle" onClick={handleThemeChange}>
                <div id="lightDiv">
                  <img src={themImg.sun} alt="" />
                </div>
                <img id="sunImg" src="/images/vertical.svg" alt="" />
                <div id="darkDiv">
                  <img src={themImg.moon} alt="" />
                </div>
              </button>

              <div className="header-right-side">
                <button
                  onClick={() => {
                    window.open(
                      "https://rinex-s-school.thinkific.com/users/sign_up",
                      "_blank"
                    );
                  }}
                  className="btn-signUp"
                >
                  <img className="person-icon" alt="" />
                  Sign Up
                </button>

                <a
                  href="https://rinex-s-school.thinkific.com/users/sign_in"
                  target="_blank"
                  className="btn-logIn"
                >
                  <img src="/images/login_icon.svg" alt="" /> Login
                </a>
              </div>

              <div id="program-categories-modal-section">
                <div id="program-categories-modal">
                  <div
                    className="program-modal-head"
                    onClick={closeProgramCategories}
                  >
                    <div>
                      <p className="program-menu-para">PROGRAMS</p>
                      <h6 className="program-menu-heading">See all programs</h6>
                    </div>
                    <img
                      className="closeMnu"
                      src="/images/programm_icon.svg"
                      alt=""
                    />
                  </div>

                  <img src="/images/hor_devider.svg" alt="" />

                  <div
                    className="program-categories"
                    value="csProg"
                    onClick={openCsProgramModal}
                  >
                    <img src="/images/cs_logo.svg" alt="" />
                    <div>
                      <h6 className="program-menu-option">
                        Computer Science/Information Science
                      </h6>
                    </div>
                  </div>

                  <div
                    className="program-categories"
                    id="civilProg"
                    onClick={openCivilProgramModal}
                  >
                    <img src="/images/civil_logo.svg" alt="" />
                    <div>
                      <h6 className="program-menu-option">Civil Engineering</h6>
                    </div>
                  </div>

                  <div
                    className="program-categories"
                    id="extcProg"
                    onClick={openExtcProgramModal}
                  >
                    <img src="/images/extc_logo.svg" alt="" />
                    <div>
                      <h6 className="program-menu-option">
                        Electronics & Communication Engineering
                      </h6>
                    </div>
                  </div>

                  <div
                    className="program-categories"
                    id="mechProg"
                    onClick={openMechProgramModal}
                  >
                    <img src="/images/mech_logo.svg" alt="" />
                    <div>
                      <h6 className="program-menu-option">
                        Mechanical Engineering
                      </h6>
                    </div>
                  </div>
                </div>

                <div
                  id="program-subcategories-modal"
                  onClick={openProgramCategories}
                >
                  <div
                    className="program-modal-head"
                    onClick={closeProgramSubCategoriesModal}
                  >
                    <img className="subCatArrow" alt="" />
                    <p className="program-menu-para">{progCat}</p>
                  </div>

                  <img src="/images/hor_devider.svg" alt="" />

                  {subCategories.map((item, index) => (
                    <Link
                      onClick={closeMainMenu}
                      to={`/program/${item.id}`}
                      key={index}
                      className="program-categories"
                    >
                      <img src={item.logo} alt="" />
                      <div>
                        <h6 className="program-menu-option">
                          {item.programName}
                        </h6>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
              {/* Elite Modal Section */}
              <div id="elite-modal-section" style={{ display: "none" }}>
                <div id="elite-modal">
                  <div className="elite-modal-head">
                    <div className="elite-modal-head-left">
                      <p className="elite-menu-para">ELITE</p>
                      <h6 className="elite-menu-heading">
                        See all Elite options
                      </h6>
                    </div>
                    {/* <button className="closeMnu" onClick={closeEliteModal}> */}
                    <img
                      className="closeicon"
                      src="/images/menu_close_button.svg"
                      alt="close"
                      onClick={closeEliteModal}
                    />
                    {/* </button> */}
                  </div>
                  {/* <img src="/images/hor_devider.svg" alt="" /> */}
                  <hr/>
                  <div className="elite-program-details">
                    <div
                      className="elite-option"
                      onClick={() =>
                        (window.location.href = "/ep/DSAPython.html")
                      }
                    >
                      <img src="/images/pythonElite.png" alt="Elite Icon" />
                      <h6>Data Structures and Algorithms in Python</h6>
                    </div>
                    <div
                      className="elite-option"
                      onClick={() =>
                        (window.location.href = "/ep/DSAJava.html")
                      }
                    >
                      <img src="/images/javaElite.png" alt="Elite Icon" />
                      <h6>Data Structures and Algorithms in Java</h6>
                    </div>
                    <div
                      className="elite-option"
                      onClick={() => (window.location.href = "/ep/DSAC++.html")}
                    >
                      <img src="/images/c++Elite.png" alt="Elite Icon" />
                      <h6>Data Structures and Algorithms in C++</h6>
                    </div>
                    <div
                      className="elite-option"
                      onClick={() => (window.location.href = "/ep/uiux.html")}
                    >
                      <img src="/images/uiuxElite.png" alt="Elite Icon" />
                      <h6>UI/UX</h6>
                    </div>
                    <div
                      className="elite-option"
                      onClick={() =>
                        (window.location.href = "/ep/digitalmarketing.html")
                      }
                    >
                      <img src="/images/digitalmarketingElite.png" alt="Elite Icon" />
                      <h6>Digital Marketing</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* mobile header ended ---- */}

          <Routes>
            <Route
              path="/"
              element={
                <Home
                  addSmoothScrolling={addBlogScrollSmooth}
                  removeSmoothScrolling={removeBlogScrollSmooth}
                />
              }
            ></Route>
            <Route path="/about" element={<AboutUs />}></Route>
            <Route
              path="/program/:programId"
              element={
                <Programs
                  addSmoothScrolling={addBlogScrollSmooth}
                  removeSmoothScrolling={removeBlogScrollSmooth}
                />
              }
            ></Route>
            <Route
              path="/career"
              element={
                <Career
                  addSmoothScrolling={addBlogScrollSmooth}
                  removeSmoothScrolling={removeBlogScrollSmooth}
                />
              }
            ></Route>
            <Route path="/job/:jobrole" element={<JobRole />}></Route>
            <Route path="/blog/:blogId" element={<Blog />}></Route>
            <Route
              path="/freeprogram/:freeprogramId"
              element={<FreeProgram />}
            ></Route>
          </Routes>
          <footer>
            {/* slogan section started ---- */}
            <div className="slogan-container">
              <img className="slogan-logo" alt="" />
              <h6 className="sloag-heading">Educate.Improve.Inspire</h6>
            </div>
            {/* slogan section ended ----- */}

            {/* site map section started ------- */}
            <div className="site-map-section">
              <div className="site-map-left-side">
                <p className="site-map-para">SITE MAP</p>
                <h6 className="site-map-heading">Find all our links here</h6>
                <p className="site-coffee-para">MEET FOR A COFFEE HERE</p>
                <p className="footer-adress">
                  Rinex Technologies Pvt. Ltd. Enzyme Tech Park, 1113, 6th Main
                  Rd, Syndicate Bank Colony, Sector 7, HSR Layout, Bengaluru,
                  Karnataka 560102
                </p>
              </div>

              <div className="site-map-right-side">
                <ul>
                  <li className="link-heading">EXPLORE</li>
                  <li className="">
                    <a href="/" className="nav-foot-link">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="/#partnerNav" className="nav-foot-link">
                      Partner
                    </a>
                  </li>
                  <li>
                    <a href="/#contactNav" className="nav-foot-link">
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a href="/#communityNav" className="nav-foot-link">
                      Community
                    </a>
                  </li>
                  <li>
                    <a href="/#reviewNav" className="nav-foot-link">
                      Reviews
                    </a>
                  </li>
                  <li>
                    <a href="/#demoNav" className="nav-foot-link">
                      Demo Session
                    </a>
                  </li>
                  <li>
                    <a href="/certificateVerify.html" className="nav-foot-link">
                      <div>
                        <span
                          onClick={() =>
                            (window.location.href = "/certificateVerify.html")
                          }
                        >
                          Verify Certificate
                        </span>
                      </div>
                    </a>
                  </li>
                </ul>

                <ul>
                  <li className="link-heading">PROGRAMS</li>
                  {/* <li>
                    <Link to="/program/1" className="nav-foot-link">
                      Data Structures and Algorithms
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/program/1" className="nav-foot-link">
                      Artificial Intelligence
                    </Link>
                  </li>
                  <li>
                    <Link to="/program/4" className="nav-foot-link">
                      Machine Learning
                    </Link>
                  </li>
                  <li>
                    <Link to="/program/3" className="nav-foot-link">
                      Data Science
                    </Link>
                  </li>
                  <li>
                    <Link to="/program/2" className="nav-foot-link">
                      Cyber Security and ethical hacking
                    </Link>
                  </li>
                  <li>
                    <Link to="/program/6" className="nav-foot-link">
                      AutoCAD
                    </Link>
                  </li>
                  <li>
                    <Link to="/program/7" className="nav-foot-link">
                      Internet of Things and Robotics
                    </Link>
                  </li>
                  <li>
                    <Link to="/program/8" className="nav-foot-link">
                      AutoCAD & MATLAB
                    </Link>
                  </li>
                </ul>

                <ul>
                  <li className="link-heading">COMPANY</li>
                  <li>
                    <Link to="/about" className="nav-foot-link">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <a
                      onMouseOver={addBlogScrollSmooth}
                      onMouseOut={removeBlogScrollSmooth}
                      href="/#blogNav"
                      className="nav-foot-link"
                    >
                      Blogs
                    </a>
                  </li>
                  <li>
                    <Link to="/career" className="nav-foot-link">
                      Career <span className="hiring-chip">WE ARE HIRING</span>
                    </Link>
                  </li>
                </ul>

                <ul>
                  <li className="link-heading">USER</li>
                  <li>
                    <a
                      className="footerSignIn"
                      style={{ textDecoration: "none" }}
                      href="https://rinex-s-school.thinkific.com/users/sign_in"
                      target="_blank"
                    >
                      Login
                    </a>
                  </li>
                  <li>
                    <a
                      className="footerSignUp"
                      style={{ textDecoration: "none" }}
                      href="https://rinex-s-school.thinkific.com/users/sign_up"
                      target="_blank"
                    >
                      Sign Up
                    </a>
                  </li>
                </ul>

                <p className="site-coffee-para-mobile">
                  MEET FOR A COFFEE HERE
                </p>
                <p className="footer-adress-mobile">
                  Rinex Technologies Pvt. Ltd. Enzyme Tech Park, 1113, 6th Main
                  Rd, Syndicate Bank Colony, Sector 7, HSR Layout, Bengaluru,
                  Karnataka 560102
                </p>
              </div>
            </div>
            {/* site map section ended ------- */}

            {/* social media section ---- */}
            <div className="social-media-section">
              <h5 className="social-media-heading">
                Find us on{" "}
                <img className="red-heart" src="/images/red_heart.svg" alt="" />{" "}
                social media
              </h5>

              <div>
                <a
                  href="https://www.linkedin.com/company/rinex-ai/mycompany/"
                  target="_blank"
                  className="social-nav-link"
                >
                  <img src="/images/linkedin_logo.svg" alt="" />
                  LinkedIn <img src="/images/tilt_arrow_grey.svg" alt="" />
                </a>
                <a
                  href="mailto:help@rinex.ai"
                  target="_blank"
                  className="social-nav-link"
                >
                  <img src="/images/mail_logo.svg" alt="" />
                  Mail <img src="/images/tilt_arrow_grey.svg" alt="" />
                </a>
                <a
                  style={{ cursor: "not-allowed" }}
                  target="_blank"
                  className="social-nav-link"
                >
                  <img src="/images/twitter_logo.svg" alt="" />X{" "}
                  <img src="/images/tilt_arrow_grey.svg" alt="" />
                </a>
                <a
                  href="https://www.instagram.com/rinex_ai/"
                  target="_blank"
                  className="social-nav-link"
                >
                  <img src="/images/insta_logo.svg" alt="" />
                  Instagram <img src="/images/tilt_arrow_grey.svg" alt="" />
                </a>
                <a
                  href="https://www.facebook.com/rinexai/?_rdr"
                  target="_blank"
                  className="social-nav-link"
                >
                  <img src="/images/fb_logo.svg" alt="" />
                  Facebook <img src="/images/tilt_arrow_grey.svg" alt="" />
                </a>
              </div>
            </div>
            {/* social media ssection ended ---- */}

            {/* copyright section started ------ */}
            <div className="copyright-section">
              <p className="copyright-para">©Rinex 2020 · 2024</p>

              <div className="copyright-nav-container">
                <a href="/termsandconditions.html">User Agreement</a>
                <a href="/termsandconditions.html">Terms and Condition</a>
                <a href="/privacypolicy.html">Privacy policy</a>
              </div>
            </div>
            {/* copyright section ended  */}

            {/* footer brand banner section started --- */}
            <div className="footer-brand-banner-section">
              <p className="footer-slogan">EDUCATE. IMPROVE. INSPIRE</p>
              <img src="/images/rinex_footer_banner.png" alt="" />
            </div>
          </footer>
          {/* footer ended ---------- */}
        </BrowserRouter>
      </div>
    </React.Fragment>
  );
}
