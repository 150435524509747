import React, { useEffect, useState } from "react"
import freeprogramData from "../data/freeprogram.json";
import { useParams } from "react-router-dom";


export function FreeProgram(){
    const[freeProgramDetails, setFreeProgramDetails] = useState({});
    const[programCurriculum, setProgramCurriculum] = useState([]);
    const params = useParams();

    function getFreeProgramDetail(){
        var selectedProgram = freeprogramData.find(item => item.id === params.freeprogramId);
        if(selectedProgram){
            setFreeProgramDetails(selectedProgram);
            setProgramCurriculum(selectedProgram.curriculum)
        } 
    }

    function handleProgramRegistration(){
        document.getElementById("btn-green-tag").style.transform = "translateX(115%)";
        document.getElementById("btn-green-tag").style.backgroundColor="red"
        document.getElementById("btn-green-tag").style.transition="0.7s ease-out"

        // setTimeout(function() {
        //     window.open(freeProgramDetails.links, "_blank")
        // }, 600);

        setTimeout(function() {
            document.getElementById("btn-green-tag").style.transform = "translateX(0%)";
            document.getElementById("btn-green-tag").style.backgroundColor = freeProgramDetails.tagColor;
            document.getElementById("btn-green-tag").style.transition="0s"
        }, 1000);
    }

    useEffect(()=>{
        getFreeProgramDetail();
    },[params])

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    return(
        <React.Fragment>
            {/* free course fundamental section started ----- */}
            <div className="free-course-fundamental-section">
                <div className="free-course-fundamental-contiainer">
                    <div className="head-color-line" style={{backgroundColor:`${freeProgramDetails.tagColor}`}}></div>
                    <div className="free-course-head-para-container">
                        <p className="free-program-field-para">{freeProgramDetails.programField}</p>
                        <div>
                            <span><img src="/images/free_icon.svg" alt="" />Free</span>
                            <span><img src="/images/pad_icon.svg" alt="" />{programCurriculum.length} Lession</span>
                            <span><img src="/images/vid_icon.svg" alt="" />{programCurriculum.length} Lessons</span>
                        </div>
                    </div>
                    <div className="free-course-title-container">
                        <h4 className="free-course-title">Fundamentals of {freeProgramDetails.programTitle}</h4>
                        <div className="program-tags-mobile">
                            <span><img src="/images/free_icon.svg" alt="" />Free</span>
                            <span><img src="/images/pad_icon.svg" alt="" />{programCurriculum.length} Lession</span>
                            <span><img src="/images/vid_icon.svg" alt="" />{programCurriculum.length} Lessons</span>
                        </div>
                        <button onClick={handleProgramRegistration}  className="btn-enroll-free-program">
                            <div style={{backgroundColor:`${freeProgramDetails.tagColor}`}} id="btn-green-tag">Enroll for free</div>
                            <img src="/images/right_arrows.svg" alt="" />
                        </button>
                    </div>
                </div>
            </div>
            {/* free course fundamental section ended ----- */}


            {/* free course curriculum-section started ----- */}
            <div className="free-course-curriculum-section">
                <p className="free-course-curriculum-para">GET STARTED</p>
                <h4 className="free-course-curriculum-heading">Know your course curriculum</h4>

                <div className="free-course-curriculum-topic-container">
                    {
                        programCurriculum.map((item, index)=>
                            <div key={index}>
                                <img src="/images/video_logo.svg" alt="" />
                                <div>
                                    <p className="session-para">SESSION {index+1}</p>
                                    <h4 className="free-course-curriculum-topic">{item}</h4>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </React.Fragment>
    )
}